import { Component, Input, Output, EventEmitter, OnInit, ElementRef, HostListener } from '@angular/core';

interface ListItem {
  PID: number;
  Name: string;
  IsSelected: boolean;
}

@Component({
  selector: 'MultiSelectionDropdown',
  templateUrl: './MultiSelectionDropdown.html'
})
export class MultiSelectionDropdown  {
  @Input() listData: ListItem[] = [];
  @Input() selectedName: string = '';
  @Input() type: number | undefined;
  @Output() updateSelectedPIDForParent: EventEmitter<any> = new EventEmitter<any>();

  showOptions: boolean = false;
  currentList: ListItem[] = [];
  selectUnSelectAll: boolean = true;

  constructor(private elementRef: ElementRef) { }

  ngOnChanges(): void {
    this.currentList = this.listData.map(item => {
        item.IsSelected = true;
      return item;
    });
    this.checkSelectUnselectAll();
  }

  updateListdata(index: number): void {
    this.currentList[index].IsSelected = !this.currentList[index].IsSelected;
    this.checkSelectUnselectAll();
  }

  sendResponseToParent(): void {
    this.showOptions = false;
    const selectedIds = this.currentList
      .filter(item => item.IsSelected)
      .map(item => item.PID)
      .join(',');
    if (selectedIds) {
      this.updateSelectedPIDForParent.emit({ type: this.type, selectedIds });
    }
  }

  checkSelectUnselectAll(): void {
    this.selectUnSelectAll = this.currentList.every(item => item.IsSelected);
  }

  selectUnselectAll(event: any): void {
    const value = event.target.checked;
    this.currentList.forEach(item => item.IsSelected = value);
    this.checkSelectUnselectAll();
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }
}
