import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DefaultTS } from './Default';

@Component({
  selector: 'ShowModal',
  templateUrl: './ShowModal.html'
})

export class ShowModal implements OnInit {
  @Input() public m_arrFormattedEvents: any[] = [];
  @Input() public AvailableResources: any[] = [];
  @Input() public NotAvailableResources: any[] = [];
  @Input() public ModalHeading = '' as any;
  @Input() public ResourceBookingGridData: any[] = [];
  @Input() public TotalAvailableResourcesGridData: any[] = [];
  public TotalAvailable = 0;
  public TotalNonAvailable = 0;
  public TooltipData = '';
  public AvailableResourcesType = [] as any;
  public NonAvailableResourcesType = [] as any;
  constructor(private Default: DefaultTS) {}
  ngOnInit() {
    //document.getElementById("ShowModal").click()
  }
  public ShowFormattedJsonData() {
    document.getElementById("ShowModal2")!.click();
  }

  public ShowResourcesAvailability() {
    var l_intCounter = 0;
    this.TotalNonAvailable = 0;
    this.TotalAvailable = 0;
    this.AvailableResourcesType = [];
    this.NonAvailableResourcesType = [];
    for (var i = 0; i < this.AvailableResources.length; i++) {
      this.TotalAvailable = this.AvailableResources[i].Quantity + this.TotalAvailable;
      var l_intIndex = this.Default.GetIndexExactlyMatchingColumn(this.AvailableResourcesType, 'PFIResourceTypeID', this.AvailableResources[i].PFIResourceTypeID);
      if (l_intIndex > -1) {
        this.AvailableResourcesType[l_intIndex].Quantity = this.AvailableResources[i].Quantity + this.AvailableResourcesType[l_intIndex].Quantity;
      }
      else {
        this.AvailableResourcesType[l_intCounter] = Object.assign({}, this.AvailableResources[i]);
        l_intCounter++;
      }
    }
    var l_intCounter = 0;
    for (var i = 0; i < this.NotAvailableResources.length; i++) {
      this.TotalNonAvailable = this.NotAvailableResources[i].Quantity + this.TotalNonAvailable;
      var l_intIndex = this.Default.GetIndexExactlyMatchingColumn(this.NonAvailableResourcesType, 'PFIResourceTypeID', this.NotAvailableResources[i].PFIResourceTypeID);
      if (l_intIndex > -1) {
        this.NonAvailableResourcesType[l_intIndex].Quantity = this.NotAvailableResources[i].Quantity + this.NonAvailableResourcesType[l_intIndex].Quantity;
      }
      else {
        this.NonAvailableResourcesType[l_intCounter] = Object.assign({}, this.NotAvailableResources[i]);
        l_intCounter++;
      }
    }
    document.getElementById("aresourcesAvailability")!.click();
  }
  public ShowToolTipData(PFIResource: any, l_boolAvailable: any) {
    this.TooltipData = '';
    var l_arrSelectedResourcesStatus = [] as any;
    if (l_boolAvailable)
      l_arrSelectedResourcesStatus = Object.assign([], this.AvailableResources);
    else
      l_arrSelectedResourcesStatus = Object.assign([], this.NotAvailableResources);

    var l_intResourceTypeID = 0;
    if (PFIResource == 0)
      l_intResourceTypeID = 0;
    else
      l_intResourceTypeID = PFIResource.PFIResourceTypeID;

    for (var i = 0; i < l_arrSelectedResourcesStatus.length; i++) {
      if (l_arrSelectedResourcesStatus[i].PFIResourceTypeID == l_intResourceTypeID || (l_intResourceTypeID == 0)) {
        if (l_arrSelectedResourcesStatus[i].Quantity > 0) {
          var l_arrFilteredData = this.Default.FilterJsonExactlyMatching(this.TotalAvailableResourcesGridData, 'PFIResourceID', l_arrSelectedResourcesStatus[i].PFIResourceID);
          if (l_arrFilteredData.length > 0) {
            var a = document.createElement('span');
            a.innerHTML = l_arrFilteredData[0].PFIResourceName + ' (' + l_arrSelectedResourcesStatus[i].Quantity + ') <br>';
            this.TooltipData += a.innerText;
          }
        }
      }
    }
  }
}
