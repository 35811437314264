import { Component, Input, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DefaultTS } from '../SharedComponents/Default';
import { SafeHtml } from '@angular/platform-browser';
import { JsonLdService } from 'ngx-seo';


@Injectable()
@Component({
  selector: 'UserRating',
  templateUrl: './UserRating.html'
})

export class UserRating {
  public http: HttpClient;
  @Input() public UserReview = [] as any;
  @Input() public TotalRating = 0 as number;
  @Input() public PageName = 'London';
  @Input() public IsReferenceScreen = false;
  @Input() public UserReviewAggergate = {} as any;
  public rating = 3.5;
  constructor(http: HttpClient, private router: Router, private Default: DefaultTS, private readonly jsonLdService: JsonLdService) {
    this.http = http;
  }

  ngOnChanges() {
    
    const jsonLd = {
      "@context": "http://schema.org/",
      "@type": "Product",
      "name": "Finest Man Van",
      "brand": {
        "@type": "Thing",
        "name": "Man and Van & Removal Services in " + this.PageName
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": '' + this.TotalRating.valueOf() + '',
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": '' + Number(this.UserReview.length).valueOf() + ''
      }
    }

    this.jsonLdService.setData(jsonLd);
  }

}
