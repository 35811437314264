

<ng-container *ngIf="AllLinkedQuotes.length > 0">
  <ng-container *ngFor="let Service of AllLinkedQuotes;let index=index">
    <app-ServiceInfo [QuoteID]="Service.MNVQuoteID" [TotalServices]="AllLinkedQuotes.length" [ServiceNumber]="index+1"></app-ServiceInfo>
  </ng-container>
</ng-container>

<ng-container *ngIf="AllLinkedQuotes.length == 0">
  <app-ServiceInfo [QuoteID]="MNVQuoteID" [TotalServices]="1" [ServiceNumber]="1" [IsVanLevel]="true"></app-ServiceInfo>
</ng-container>

<table class="table table-striped table-sm text-nowrap" *ngIf="AllLinkedQuotes.length > 1">
  <tbody>

    <ng-container *ngIf="JobRelatedData.Amounts">
      <tr>
        <th colspan="2">Subtotal </th>
        <th colspan="3">	£{{JobRelatedData.Amounts.PayableAmount - JobRelatedData.Amounts.Vat | number:'1.2-2'}}</th>
      </tr>
      <tr>
        <th colspan="2">VAT </th>
        <th colspan="3">	£{{JobRelatedData.Amounts.Vat | number:'1.2-2'}}</th>
      </tr>
      <tr>
        <th colspan="2">Total Amount </th>
        <th colspan="3">	£{{JobRelatedData.Amounts.PayableAmount | number:'1.2-2'}}</th>
      </tr>
    </ng-container>
  </tbody>
</table>

<!--<div class="container Manage_grid">
  <div id="Step5" style="display: inline-block;width: 100%;padding: 1rem;border-radius: .25rem;background: #fff;box-shadow: 0 0 10px rgba(94, 44, 237, 0.2);">
    <h3 style="padding: .5rem 1rem;background: rgba(0,0,0,.03);margin: -16px -16px 20px -16px;border-bottom: 1px solid rgba(0,0,0,.125);font-size: 26px;">Order Detail</h3>
    <div class="mn-order-main" style="column-gap:40px;">
      <div class="mn-order-01" id="detail">
        <div class="mn-order-1">
          <div class="mn-order-2">
            <span class="mn-order-3">Contact Name:</span>
            <span class="mn-order-4">{{QuoteInfo.ClientName}}</span>
            <span class="mn-order-4">{{QuoteInfo.Mobile}}</span>
          </div>
          <div class="mn-order-2">
            <span class="mn-order-3">Pickup Address:</span>
            <span class="mn-order-4">{{QuoteInfo.FromPostalCode}}</span>
            <span class="mn-order-4">{{QuoteInfo.FromAddress}}</span>
            <span class="mn-order-4">{{QuoteInfo.FromCity}}</span>
            <div class="flex-display mn-pipe">
              <span class="mn-order-4">{{QuoteInfo.FromType}}</span>
              <span class="mn-pipe-1">|</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.FromNumberOfStairs > 0">{{Floors[QuoteInfo.FromNumberOfStairs]}}</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.FromNumberOfStairs == -1">Ground Floor</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.FromNumberOfStairs == -2">Basement</span>
              <span class="mn-pipe-1">|</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.FromAvailableLift == 'No'">{{QuoteInfo.FromAvailableLift}} Lift</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.FromAvailableLift == 'Yes'">Lift Available</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.FromAvailableLift == 'N/A'">N/A</span>
            </div>
          </div>
          <div class="mn-order-2">
            <span class="mn-order-3">Delivery Address:</span>
            <span class="mn-order-4">{{QuoteInfo.ToPostalCode}}</span>
            <span class="mn-order-4">{{QuoteInfo.ToAddress}}</span>
            <span class="mn-order-4">{{QuoteInfo.ToCity}}</span>
            <div class="flex-display mn-pipe">
              <span class="mn-order-4">{{QuoteInfo.ToType}}</span>
              <span class="mn-pipe-1">|</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.ToNoOfStairs > 0">{{Floors[QuoteInfo.ToNoOfStairs]}}</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.ToNoOfStairs == -1">Ground Floor</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.ToNoOfStairs == -2">Basement</span>
              <span class="mn-pipe-1">|</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.ToAvailableLift == 'No'">{{QuoteInfo.ToAvailableLift}} Lift</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.ToAvailableLift == 'Yes'">Lift Available</span>
              <span class="mn-order-4" *ngIf="QuoteInfo.ToAvailableLift == 'N/A'">N/A</span>
            </div>
          </div>
        </div>
        <div class="mn-order-1">
          <div class="mn-order-2">
            <span class="mn-order-3">Time & Date:</span>
            <span class="mn-order-4">{{QuoteInfo.RequestedDate | date:'fullDate'}}</span>
            <span class="mn-order-4">{{QuoteInfo.RequestedTime}}</span>
          </div>
          <div class="mn-order-2">
            <span class="mn-order-3">Distance:</span>
            <span class="mn-order-4">{{QuoteInfo.PTDDistance |number:'1.2-2' }} miles</span>
          </div>
          <div class="mn-order-2">
            <span class="mn-order-3">Extra Stops:</span>
            <span class="mn-order-4">{{MNVQuoteAdditionalStops.length==0?'No':'Yes'}}</span>
          </div>
        </div>

        <ng-container *ngIf="MNVQuoteAdditionalStops.length">
          <span class="mn-order-3">Additional Stops</span>
          <div class="mn-order-1 mn-order-hr" style="margin-bottom:20px;"></div>
          <div class="mn-order-1">
            <ng-container *ngFor="let stop of MNVQuoteAdditionalStops;let index=index">

              <div class="mn-order-2">
                <span class="mn-order-3">Stop {{index+1}}:</span>
                <span class="mn-order-4">{{stop.PostCode}}</span>
                <span class="mn-order-4">{{stop.Address}}</span>
                <span class="mn-order-4">{{stop.City}}</span>
                <div class="flex-display mn-pipe">
                  <span class="mn-order-4">{{stop.AdditionalStopType}}</span>
                  <span class="mn-pipe-1">|</span>
                  <span class="mn-order-4" *ngIf="stop.NoOfStairs > 0">{{Floors[stop.NoOfStairs]}}</span>
                  <span class="mn-order-4" *ngIf="stop.NoOfStairs == -1">Ground Floor</span>
                  <span class="mn-order-4" *ngIf="stop.NoOfStairs == -2">Basement</span>
                  <span class="mn-pipe-1">|</span>
                  <span class="mn-order-4" *ngIf="stop.AdditionalStopAvailableLift == 'No'">{{stop.AdditionalStopAvailableLift}} Lift</span>
                  <span class="mn-order-4" *ngIf="stop.AdditionalStopAvailableLift == 'Yes'">Lift Available</span>
                  <span class="mn-order-4" *ngIf="stop.AdditionalStopAvailableLift == 'N/A'">N/A</span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div class="mn-order-1 mn-order-hr"></div>
        <div class="mn-order-1">
          <div class="mn-order-2">
            <span class="mn-order-3">Van Size:</span>
            <span class="mn-order-4">
              <img style="height:70px;" *ngIf="QuoteInfo.MNVVanTypeID==1" src="assets/img/movers/transit.svg">
              <img style="height:70px;" *ngIf="QuoteInfo.MNVVanTypeID==2" src="assets/img/movers/luton.svg">
              <br />
              <span *ngIf="QuoteInfo.MNVVanTypeID==1">Transit</span>
              <span *ngIf="QuoteInfo.MNVVanTypeID==2">Luton</span>
            </span>
          </div>
          <div class="mn-order-2">
            <span class="mn-order-3">Movers:</span>
            <span class="mn-order-4">
              <img *ngIf="QuoteInfo.MNVMoverTypeID==1" alt="img" src="assets/img/movers/onemover.png" style="height:70px;" />
              <img *ngIf="QuoteInfo.MNVMoverTypeID==2" alt="img" src="assets/img/movers/twomovers.png" style="height:70px;" />
              <img *ngIf="QuoteInfo.MNVMoverTypeID==3" alt="img" src="assets/img/movers/threemovers.png" style="height:70px;" />
              <br />
              <span *ngIf="QuoteInfo.MNVMoverTypeID==1">One Mover</span>
              <span *ngIf="QuoteInfo.MNVMoverTypeID==2">Two Mover</span>
              <span *ngIf="QuoteInfo.MNVMoverTypeID==3">Three Mover</span>
            </span>
          </div>
          <div class="mn-order-2">
            <ng-container *ngIf="QuoteInfo.IsLocalQuote==true else Long">
              <span class="mn-order-3">Service Time:</span>-->
<!--<span class="mn-order-4"><i class="fa fa-clock" style="font-size: 20px; color: #482064;margin: 0 10px 0 0;position: relative;top: 2px;"></i>{{QuoteInfo.RequiredVanTime}} hours <button type="button" class="mn-claim" (click)="OpenDisclaimer(disclaimer)">Disclaimer</button></span>-->
<!--<div class="st-movers-1 stw100">

        <select disabled class="cs-form__field" [(ngModel)]="QuoteInfo.RequiredVanTime">
          <option value="1.5">1.5 hours</option>
          <option value="2">2 hours</option>
          <option value="2.5">2 and a half hours</option>
          <option value="3">3 hours</option>
          <option value="3.5">3 and a half hours</option>
          <option value="4">4 hours</option>
          <option value="4.5">4 and a half hours</option>
          <option value="5">5 hours</option>
          <option value="5.5">5 and a half hours</option>
          <option value="6">6 hours</option>
          <option value="6.5">6 and a half hours</option>
          <option value="7">7 hours</option>
          <option value="7.5">7 and a half hours</option>
          <option value="8">8 hours</option>
          <option value="8.5">8 and a half hours</option>
          <option value="9">9 hours</option>
          <option value="9.5">9 and a half hours</option>
          <option value="10">10 hours</option>
          <option value="10.5">10 and a half hours</option>
          <option value="11">11 hours</option>
          <option value="11.5">11 and a half hours</option>
          <option value="12">12 hours</option>
          <option value="12.5">12 hours and a half</option>
          <option value="13">13 hours</option>
          <option value="13.5">13 and a half hours</option>
          <option value="14">14 hours</option>
          <option value="14.5">14 and a half hours</option>
          <option value="15">15 hours</option>
        </select>
      </div>
    </ng-container>
    <ng-template #Long>
      Loading Time:
      <select disabled class="cs-form__field" [(ngModel)]="QuoteInfo.LoadingTime">
        <option value="15">15mins</option>
        <option value="30">30mins</option>
        <option value="45">45mins</option>
        <option value="60">1hr</option>
        <option value="90">1hr & 30mins</option>
        <option value="120">2hrs</option>
        <option value="150">2hrs & 30mins</option>
        <option value="180">3hrs</option>
        <option value="210">3hrs & 30mins</option>
        <option value="240">4hrs</option>
        <option value="270">4hrs & 30mins</option>
        <option value="300">5hrs</option>
      </select>
      Unloading Time:
      <select disabled class="cs-form__field" [(ngModel)]="QuoteInfo.UnloadingTime">
        <option value="15">15mins</option>
        <option value="30">30mins</option>
        <option value="45">45mins</option>
        <option value="60">1hr</option>
        <option value="90">1hr & 30mins</option>
        <option value="120">2hrs</option>
        <option value="150">2hrs & 30mins</option>
        <option value="180">3hrs</option>
        <option value="210">3hrs & 30mins</option>
        <option value="240">4hrs</option>
        <option value="270">4hrs & 30mins</option>
        <option value="300">5hrs</option>
      </select>
    </ng-template>
  </div>
</div>-->
<!--<div class="mn-order-1">
  <div class="ib-display stw100" style="position:relative;">
    <span class="mn-order-3">Comments & Extra Info:</span>
    <textarea rows="3" cols="3" [(ngModel)]="QuoteInfo.ClientInstructions" class="cs-form__field"></textarea>
    <a (click)="ToggleFileUpload()" class="paper-clip"><i class="fa fa-paperclip"></i></a>
  </div>
</div>-->
<!--<table class="table table-striped table-sm text-nowrap" style="margin-bottom:30px;">
  <thead>
    <tr>
      <th colspan="2">
        {{QuoteInfo.ServiceName}}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let response of ServiceQuestionAnswers">
      <td>{{response.Questions}}</td>
      <td>

        {{response.Answers}}

      </td>
    </tr>
  </tbody>
</table>-->
<!--<div class="mn-order-1">
  <div class="mn-convo-border ib-display stw100 post_img2" *ngIf="IsShowFileUploader">
    <app-PTDragDropUpload [MaxNumberOfFiles]="1" class="FileUploader" [UploadType]="1" [ObjectType]="MessageTypeID" [ObjectID]="OtherEntityID" [PTFilesList]="PTFilesList" (ResponseFromPTUploader)="ResponseFromPTUploader($event)"></app-PTDragDropUpload>
  </div>
</div>-->
<!--<div class="mn-order-1" *ngIf="QuoteInfo.FromFloor != ''">
    <div class="ib-display stw100" style="position:relative;">
      <span class="mn-order-3">Driver Instructions:</span>
      <span>{{QuoteInfo.FromFloor}}</span>
    </div>
  </div>

  <div class="mn-order-1">
    <div class="ib-display stw100" style="position:relative;">
      <span class="mn-order-3">Comments & Extra Info:</span>
      <span>{{QuoteInfo.ClientInstructions}}</span>
    </div>
  </div>

  <div class="mn-order-1">
    <div class="mn-convo-border ib-display stw100 post_img2">
      <app-PTDragDropUpload [CanEditFiles]="false" [MaxNumberOfFiles]="0" class="FileUploader" [UploadType]="1" [ObjectType]="1" [ObjectID]="QuoteID"></app-PTDragDropUpload>
    </div>
  </div>

</div>
<div class="mn-order-02" id="order_total" *ngIf="!defaultTS.IsDriverLoggedIn && !defaultTS.IsHelperLoggedIn">
  <div class="ib-display stw100 mn-order-back">
    <h4>Your Order Total</h4>
    <div class="mn-total-1">
      <span>Services</span>
      <span>Price</span>
    </div>
    <div class="mn-total-1 mn-total-hr1"></div>
    <div class="mn-total-1">
      <span>Quote Number: {{QuoteInfo.JobID}}</span>
      <span>
        <ng-container>
          £{{QuoteInfo.TotalAmount| number:'1.2-2'}}
        </ng-container>

      </span>
    </div>-->
<!--<div class="mn-total-1 mn-apply">
  <input class="cs-form__field" [(ngModel)]="coupenCode" placeholder="Promo Code" type="text" id="txtDiscode">
  <a class="btn btn-primary" id="btnApply" (click)="VerifyCode()">Apply</a>
</div>-->
<!--<div class="mn-total-1 mn-total-hr2" style="margin-top:10px;"></div>
          <div class="mn-total-1" *ngIf="QuoteInfo.Discount != 0">
            <span></span>
            <span>
              <ng-container>
                Discount: - £{{QuoteInfo.Discount |number:'1.2-2'}}
              </ng-container>

            </span>

          </div>

          <div class="mn-total-1" *ngFor="let LineItem of LineItems">
            <ng-container *ngIf="LineItem.TransactionType==1">
              <span></span>
              <span title="{{LineItem.Notes}}">
                <ng-container>
                  {{LineItem.RefferenceNumber}}:
                  <ng-container *ngIf="LineItem.TransactionType==1">
                    &#163;{{LineItem.Amount | number:'1.2-2'}}
                  </ng-container>
                  <ng-container *ngIf="LineItem.TransactionType==2">
                    -&#163;{{LineItem.Amount | number:'1.2-2'}}
                  </ng-container>
                </ng-container>
              </span>
            </ng-container>
          </div>

          <div class="mn-total-1">
            <span></span>

            <span>
              <ng-container>
                Subtotal: £{{QuoteInfo.Charges  | number:'1.2-2'}}
              </ng-container>

            </span>
          </div>
          <div class="mn-total-1">
            <span></span>
            <span>
              VAT: £{{QuoteInfo.Vat  | number:'1.2-2'}}
            </span>

          </div>
          <div class="mn-total-1 mn-total-hr3">
            <div class="ib-display mn-50-hr"></div>
          </div>
          <div class="mn-total-1">
            <span></span>
            <span>
              <ng-container>
                Total: £{{QuoteInfo.PayableAmount | number:'1.2-2'}}<br />
              </ng-container>

            </span>
          </div>
          <div class="mn-total-1">
            <div class="ib-display stw100">
              <p>* Any additional time will be charged at £{{AdditionalRateAndVAT | number: '1.2-2'}} + VAT per 30mins. Please keep additional time up to 1hr maximum.</p>

            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</div>-->
