<div (window:resize)="onResize($event)"></div>
<div class="mn-convo-20">
<textarea rows="4" [(ngModel)]="InputFields.Content" cols="20" placeholder="Type your message here ..."></textarea>
</div>
<div class="mn-convo-21">
  <a title="Send" class="btn btn-primary" (click)="SaveMessage();">
    <i class="fa fa-paper-plane"></i>
  </a>
  <a class="btn btn-primary" title="Add Attachment" (click)="AddAttachmentMode = !AddAttachmentMode" *ngIf="!SendWAMessage">
    <i class="fa fa-paperclip"></i>
  </a>
</div>

<ng-container *ngIf="AddAttachmentMode">
          <div class="mn-convo-border">
            <app-PTDragDropUpload [MaxNumberOfFiles]="20" class="FileUploader" [UploadType]="1" [ObjectType]="MessageTypeID" [ObjectID]="OtherEntityID" [PTFilesList]="PTFilesList" (ResponseFromPTUploader)="ResponseFromPTUploader($event)"></app-PTDragDropUpload>
          </div>
</ng-container>
<div class="mn-convo-3a mn-convo-3b mn-scroll-main" [style.height.px]="AvailableHeight - 390" *ngIf="Message.length>0">
  <ng-container *ngFor="let ThisMessage of Message ; let ThisIndex=index">

    <div [ngClass]="ThisMessage.SiteUserID!=LoggedInUserID?'mn-convo-23 MessagingLeft':'mn-convo-23 MessagingRight' ">
      <a class="mn-convo-24">
        <img *ngIf="ThisMessage.UserImageURL!=''" [src]="ThisMessage.UserImageURL">
        <span *ngIf="ThisMessage.UserImageURL==''">{{ThisMessage.UserFullNameInitials}}</span>
      </a>
      <div class="mn-convo-25">
        <div class="mn-convo-25a">
          <div class="mn-convo-26">
            <span class="mn-convo-27">
              {{ThisMessage.UserFullName}}
            </span>
            <span style="display: flex;align-items: center;">
                <span *ngIf="ThisMessage.SiteUserID==LoggedInUserID" (click)="DeleteMessage(ThisMessage.MessageID)" style="cursor: pointer;font-size: 12px;margin: 0 10px 0 0;color:#482064 !important;"><i class="far fa-trash-alt"></i></span>
                <span class="mn-convo-28">
              {{ThisMessage.CreatedDateDisplay}}
                </span>
              </span>
          </div>

          <span class="mn-convo-29">{{ThisMessage.MessageContents}}</span>
          <ul class="mn-convo-30">
            <ng-container *ngFor="let ThisAttachment of Attachment">
              <li *ngIf="ThisMessage.MessageID==ThisAttachment.MessageID">
              
                <!--<a target="_blank" download="{{ThisAttachment.FileURLEncoded}}" href="{{ThisAttachment.FileURLEncoded}}">-->
                <a (click)="MediaUrl=ThisAttachment.FileURLEncoded;ShowMediaModal(MediaModal)">
                  <ng-container *ngIf="(ThisAttachment.FileURL==='Default/img/xls_icon.png'|| ThisAttachment.FileURL==='Default/img/Word.jpg'|| ThisAttachment.FileURL==='Default/img/ppt.png'|| ThisAttachment.FileURL==='Default/img/pdficon.jpg' || ThisAttachment.FileURL==='Default/img/video.png');else image">
                    <img [src]="ThisAttachment.FileURL" />
                  </ng-container>
                  <ng-template #image>
                    <img [src]="ThisAttachment.FileURLEncoded" />
                  </ng-template>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="Message.length<=0">
  <h3 class="mn-convo-10">No messages to show</h3>
</div>


<ng-template #MediaModal let-modal>
  <div class="modal-header">   
    <h4 class="modal-title"
        id="modal-basic-MediaModal">
      Media
    </h4>

    <button type="button" class="btn btn-dark" id="close"
            aria-label="Close" (click)="modal.dismiss('Cross click')" style="outline:none !important;">

      <span aria-hidden="true">
        x
      </span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col-xs-12 col-md-12 p-0">
          <div class="panel panel-default">
            <div class="panel-body">
              <span class="DataBoxes col-xs-12 col-sm-6 col-md-3 col-lg-3 ib-display" style="width:100% !important;margin-top:20px;">
                <span>
                  <video controls poster="{{MediaUrl}}" *ngIf="IsMp4">
                    <source src="{{MediaUrl}}" type="video/mp4">
                  </video>
                  <img src="{{MediaUrl}}" style="width:100%;max-width:600px;height:auto" *ngIf="!IsMp4" />
                </span>
              </span>
              <div class="save-new-client p-3">
                <a class="btn btn-primary" download="{{MediaUrl}}" href="{{MediaUrl}}">Download</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
