import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, Injectable, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DefaultTS } from '../SharedComponents/Default';

@Component({
  selector: 'app-UpdateMobile',
  templateUrl: './UpdateMobile.html'
})
export class UpdateMobile {
  public http: HttpClient;
  @Input() public ShowTwoFactor = false;
  public AccountProfile = {} as any;
  public ShowErrors = [] as any;
  public SelectedMobileNumber = '';
  constructor(http: HttpClient, private router: Router, public Default: DefaultTS, private cd: ChangeDetectorRef, private zone: NgZone, private router2: ActivatedRoute) {
    this.http = http;
    this.ShowErrors[10] = '';
    this.ShowErrors[20] = '';
    this.AccountProfile.Mobile = '';
    this.AccountProfile.Passcode = '';
    this.AccountProfile.EnterPassCode = false;
    this.LoadUserData();
  }
 

  public LoadUserData() {
    let data = {} as any;
    this.http.post('api/SiteUser/GetLoginLoginData', data).subscribe(result => {
      var Result = [] as any;
      Result = result;
      this.AccountProfile = Result[0][0] as any;
      this.AccountProfile.Passcode = '';
      this.AccountProfile.EnterPassCode = false;
      if (this.AccountProfile.Status == 1) {
        this.SelectedMobileNumber = this.AccountProfile.Mobile;
      }
      else {
        window.location.href = "login";
      }
    }, error => console.error(error));
  }


  public SendPassCode(DoIt) {
    this.ShowErrors[10] = '';
    if (this.AccountProfile.Mobile != '') {
      if (DoIt == 1) {
        this.AjaxSendPassCode();
      }
    }
    else {
      this.ShowErrors[10] = 'Please enter mobile number';
    }
  }

  public AjaxSendPassCode() {
    let data = {} as any;
    data.Mobile = this.AccountProfile.Mobile;
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/SiteUser/AjaxSendPassCode', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);
      var Result = [] as any;
      Result = result;
     var StatusData = Result[0][0] as any;
      if (StatusData.Status == 1) {
        this.AccountProfile.EnterPassCode = true;
      }
      else {
        if (StatusData.Status == 0) {
          this.Default.ShowMessage(2, StatusData.Message);
          this.ShowErrors[10] = StatusData.Message;
        }
        else {
          window.location.href = "login";
        }
      }
    }, error => console.error(error));
  }

  public VerifyPassCode(DoIt) {
    this.ShowErrors[20] = ''; 
    if (this.AccountProfile.Passcode.length==6) {
      if (DoIt == 1) {
        this.AjaxVerifyPassCode();
        this.SelectedMobileNumber = this.AccountProfile.Mobile;
      }
    }
    else {
      if (DoIt == 1) {
        this.ShowErrors[20] = 'Please enter 6 digit passcode';
      }
    }
  }

  public AjaxVerifyPassCode() {
    let data = {} as any;
    data.Mobile = this.AccountProfile.Mobile;
    data.Passcode = this.AccountProfile.Passcode;
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/SiteUser/AjaxVerifyPassCode', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);
      var Result = [] as any;
      Result = result;
      var StatusData = Result[0][0] as any;
      if (StatusData.Status == 1) {
        this.AccountProfile.EnterPassCode = false;
        this.Default.ShowMessage(1, 'Your mobile has been verified and updated successfully.');
        this.AccountProfile.MobileVerified = true;
      }
      else {
        if (StatusData.Status == 0) {
          this.Default.ShowMessage(2, StatusData.Message);
        }
        else {
          window.location.href = "login";
        }
      }
    }, error => console.error(error));
  }


  public SaveTwoFactorAuth() {
    if (this.AccountProfile.CanAvailTwoFactorAuth && this.AccountProfile.MobileVerified) {
      this.AjaxSaveTwoFactorAuth();
    }
  }

  public AjaxSaveTwoFactorAuth() {
    let data = {} as any;
    data.TwoFactorAuth = this.AccountProfile.TwoFactorAuth;
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/SiteUser/SaveTwoFactorAuth', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);
      var Response = result[0] as any;
      this.ShowErrors[19] = '';
      if (Response.Status == 1) {

        this.Default.ShowMessage(1, Response.Message);
      }

      else {
        if (Response.Status == 0) {
          this.Default.ShowMessage(2, Response.Message);
        }
        else {
          window.location.href = "login";
        }
      }

    }, error => console.error(error));

  }

  public ResponseFromMobileInput(event) {
    this.AccountProfile.Mobile = event.MobileNumber;
      this.SendPassCode(event.EnterPressed)
  }


}
