import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DefaultTS } from '../SharedComponents/Default';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { QuoteService } from '../Quote/quote.service';
import { json } from 'express';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-CompleteJob',
  templateUrl: './CompleteJob.html'
})
export class CompleteJob {

  @Input() public QuoteID = 0;
  @Input() public RelMNVQuoteAndMNVMoverID = 0;
  @Output() ClosePopupAndAutoSendSms = new EventEmitter();
  public MNVQuote = {} as any;
  public QuoteCalculations = {} as any;
  public QuoteRelatedPaymentAccount = [] as any;
  public PaymentObject: any = {} as any;
  public Error: any = {} as any;

  public SendReviewEmail = false;
  public BusinessCard = false;
  public MagneticCard = false;
  public SocialMediaLeaflet = false;

  public AdditionalServices = [] as any;
  public VanText = "";

  public JobRelatedData = {} as any;

  public IsThisLastVanToComplete = false;

  public ClientData = {} as any;
  constructor(public datepipe: DatePipe, public http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, public Default: DefaultTS, private router: Router, public service: QuoteService, private route: Router, private modalService: NgbModal) {
    this.Default.IsPorter = true;
    this.ClearPaymentInfo();
  }

  ngOnInit() {
    this.MNVQuote.AdditionalStairsCharges = 0;
    this.MNVQuote.AdditionalTimeCharges = 0;

    this.Default.ScrollToTop();
    this.GetQuoteInfoById(true);
    this.GetQuoteRelatedPaymentAccountDetails(this.QuoteID);
  }

  public GetQuoteInfoById(p_boolLoadAdditionalCharges) {
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/MNVQuote/MNVQuoteGetQuoteByID', this.QuoteID).subscribe(result => {
      this.MNVQuote = result[0][0];
      if (result[4] && result[4].length > 0) {
        this.VanText = " - Van 1";
        this.AdditionalServices = result[4];
      }
      else if (result[5] && result[5].length > 0) {
        this.AdditionalServices = result[5];
        for (var i = 0; i < result[5].length; i++) {
          if (result[5][i].MNVQuoteID == this.QuoteID) {
            this.VanText = " - Van " + (i + 2);
            break;
          }
        }
      }

      this.GetInvoiceDataByQuoteID();
      //this.LoadAllQuotesLinkedToThisJob(this.GetMainJobID());
      this.CheckIfAllTheOtherVansAreCompleted(0);

      if (p_boolLoadAdditionalCharges)
        this.GetAdditionalCharges();
      else {
        this.CalculateAdditionalTimeAmount(1);
        this.CalculateAdditionalStairsAmount();
      }

      this.ClientData.EmailAddress = this.MNVQuote.EmailAddress;
      this.ClientData.Mobile = this.MNVQuote.Mobile;

      this.Default.SetMasterAnimationV2(false);
    }, error => console.error(error));
  }

  public LoadAllQuotesLinkedToThisJob(MNVJobID) {
    let data = {} as any;
    data.MNVJobID = MNVJobID;
    this.http.post('api/MNVQuote/MNVQuoteGetDataSetByMNVJobIDCustom', data).subscribe(result => {
      if (result[1] && result[1][0])
        this.JobRelatedData.Amounts = result[1][0];
      //this.CalculatePayments();
    }, error => console.error(error));
  }

  public LineItems = [] as any;
  public LineMainItems = [] as any;
  public LineItemsAmounts = {
    Credit: 0, Debit: 0, Total: 0, Paid: 0, Refund: 0, Balance: 0, Liability: 0, LiabilityID: 0,
    PendingLineItemCount: 0, OtherDiscount: 0, OverAllOtherDiscount: 0, Cancellation: 0, OverallCancellation: 0, OverAllCredit: 0, OverAllDebit: 0,
    OverAllRefund: 0, OverAllSubtotal: 0, OverAllVAT: 0, OverAllTotal: 0, OverAllPaid: 0, OverAllBalance: 0
  };

  public GetInvoiceDataByQuoteID() {
    let data = {} as any;
    data.MNVQuoteID = this.GetMainJobID();
    this.http.post('api/MNVQuote/GetInvoiceDataByQuoteID', data).subscribe(result => {

      var l_arrResponseInvoice = result[0] as any;
      var l_arrResponseLineItems = result[1] as any;
      this.LineMainItems = result[2] as any;

      var l_arrLineItems = [] as any;

      this.LineItemsAmounts.Balance = 0;
      this.LineItemsAmounts.Credit = 0;
      this.LineItemsAmounts.Debit = 0;
      this.LineItemsAmounts.Refund = 0;
      this.LineItemsAmounts.Cancellation = 0;
      this.LineItemsAmounts.OverallCancellation = 0;

      this.LineItemsAmounts.OverAllCredit = 0;
      this.LineItemsAmounts.OverAllDebit = 0;
      this.LineItemsAmounts.OverAllRefund = 0;
      this.LineItemsAmounts.OverAllSubtotal = 0;
      this.LineItemsAmounts.OverAllVAT = 0;
      this.LineItemsAmounts.OverAllTotal = 0;
      this.LineItemsAmounts.OverAllPaid = 0;
      this.LineItemsAmounts.OverAllBalance = 0;
      this.LineItemsAmounts.OverAllOtherDiscount = 0;

      if (l_arrResponseInvoice.length > 0) {
        this.LineItemsAmounts.Balance = l_arrResponseInvoice[0].Balance;
        this.QuoteCalculations.AmountBeforeVAT = l_arrResponseInvoice[0].Balance / 1.2;
        this.QuoteCalculations.TotalVAT = this.LineItemsAmounts.Balance - this.QuoteCalculations.AmountBeforeVAT;
      }

      for (var i = 0; i < l_arrResponseLineItems.length; i++) {
        if (l_arrResponseLineItems[i].SourceType == 2 || l_arrResponseLineItems[i].SourceType == 3) {
          if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
            if (l_arrResponseLineItems[i].RefferenceNumber.includes("Additional") && (l_arrResponseLineItems[i].RefferenceNumber.includes("hour") || l_arrResponseLineItems[i].RefferenceNumber.includes("minute") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stair") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stairs") || l_arrResponseLineItems[i].RefferenceNumber.includes("Time"))) {
              l_arrLineItems.push(l_arrResponseLineItems[i]);
              if (l_arrResponseLineItems[i].RefferenceNumber.includes(this.VanText))
                l_arrLineItems[l_arrLineItems.length - 1].CanDelete = true;
            }
          }
          else {
            for (var as = 0; as < this.AdditionalServices.length; as++) {
              if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
                if (l_arrResponseLineItems[i].RefferenceNumber.includes("Additional") && (l_arrResponseLineItems[i].RefferenceNumber.includes("hour") || l_arrResponseLineItems[i].RefferenceNumber.includes("minute") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stair") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stairs") || l_arrResponseLineItems[i].RefferenceNumber.includes("Time"))) {
                  l_arrLineItems.push(l_arrResponseLineItems[i]);
                  if (l_arrResponseLineItems[i].RefferenceNumber.includes(this.VanText))
                    l_arrLineItems[l_arrLineItems.length - 1].CanDelete = true;
                }
              }
            }
          }
        }
      }

      this.LineItems = l_arrLineItems;

      //var l_decPaidViaThirdParty = 0;
      //var l_decOverAllPaidViaBankCashEtc = 0;
      //var l_decPaidViaBankCashEtc = 0;
      //var l_decOtherDiscountWriteOff = 0;
      //var l_decOverAllOtherDiscountWriteOff = 0;

      //for (var i = 0; i < l_arrResponseLineItems.length; i++) {

      //  if (l_arrResponseLineItems[i].Status == 1) {
      //    this.LineItemsAmounts.PendingLineItemCount += 1;
      //  }

      //  if (l_arrResponseLineItems[i].SourceType == 2 || l_arrResponseLineItems[i].SourceType == 3) {

      //    if (l_arrResponseLineItems[i].TransactionType == 1) {
      //      this.LineItemsAmounts.OverAllCredit += l_arrResponseLineItems[i].Amount;
      //    }
      //    else {
      //      this.LineItemsAmounts.OverAllDebit += l_arrResponseLineItems[i].Amount;
      //    }

      //    if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
      //      if (l_arrResponseLineItems[i].RefferenceNumber.includes("Additional") && (l_arrResponseLineItems[i].RefferenceNumber.includes("hour") || l_arrResponseLineItems[i].RefferenceNumber.includes("minute") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stair") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stairs") || l_arrResponseLineItems[i].RefferenceNumber.includes("Time"))) {
      //        l_arrLineItems.push(l_arrResponseLineItems[i]);
      //        if (l_arrResponseLineItems[i].RefferenceNumber.includes(this.VanText))
      //          l_arrLineItems[l_arrLineItems.length - 1].CanDelete = true;
      //      }

      //      if (l_arrResponseLineItems[i].TransactionType == 1) {
      //        this.LineItemsAmounts.Credit += l_arrResponseLineItems[i].Amount;
      //      }
      //      else {
      //        this.LineItemsAmounts.Debit += l_arrResponseLineItems[i].Amount;
      //      }
      //    }
      //    else {
      //      for (var as = 0; as < this.AdditionalServices.length; as++) {
      //        if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
      //          if (l_arrResponseLineItems[i].RefferenceNumber.includes("Additional") && (l_arrResponseLineItems[i].RefferenceNumber.includes("hour") || l_arrResponseLineItems[i].RefferenceNumber.includes("minute") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stair") || l_arrResponseLineItems[i].RefferenceNumber.includes("Stairs") || l_arrResponseLineItems[i].RefferenceNumber.includes("Time"))) {
      //            l_arrLineItems.push(l_arrResponseLineItems[i]);
      //            if (l_arrResponseLineItems[i].RefferenceNumber.includes(this.VanText))
      //              l_arrLineItems[l_arrLineItems.length - 1].CanDelete = true;
      //          }
      //          if (l_arrResponseLineItems[i].TransactionType == 1) {
      //            this.LineItemsAmounts.Credit += l_arrResponseLineItems[i].Amount;
      //          }
      //          else {
      //            this.LineItemsAmounts.Debit += l_arrResponseLineItems[i].Amount;
      //          }
      //        }
      //      }
      //    }
      //  }

      //  if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 2) //ObjectType=2 means PaidViaThirdParty
      //    l_decPaidViaThirdParty += l_arrResponseLineItems[i].Amount;

      //  if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 3) { //ObjectType=3 means manual paid
      //    l_decOverAllPaidViaBankCashEtc += l_arrResponseLineItems[i].Amount;

      //    if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
      //      l_decPaidViaBankCashEtc += l_arrResponseLineItems[i].Amount;
      //    }
      //    else {
      //      for (var as = 0; as < this.AdditionalServices.length; as++) {
      //        if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
      //          l_decPaidViaBankCashEtc += l_arrResponseLineItems[i].Amount;
      //        }
      //      }
      //    }
      //  }

      //  if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 4) { //ObjectType=4 means Other debit.. adjustment, other discount etc..
      //    l_decOverAllOtherDiscountWriteOff += l_arrResponseLineItems[i].Amount;

      //    if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
      //      l_decOtherDiscountWriteOff += l_arrResponseLineItems[i].Amount;
      //    }
      //    else {
      //      for (var as = 0; as < this.AdditionalServices.length; as++) {
      //        if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
      //          l_decOtherDiscountWriteOff += l_arrResponseLineItems[i].Amount;
      //        }
      //      }
      //    }
      //  }

      //  if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 5) { //ObjectType=5 means cancellation
      //    this.LineItemsAmounts.OverallCancellation += l_arrResponseLineItems[i].Amount;

      //    if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
      //      this.LineItemsAmounts.Cancellation += l_arrResponseLineItems[i].Amount;
      //    }
      //    else {
      //      for (var as = 0; as < this.AdditionalServices.length; as++) {
      //        if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
      //          this.LineItemsAmounts.Cancellation += l_arrResponseLineItems[i].Amount;
      //        }
      //      }
      //    }

      //  }

      //  if (l_arrResponseLineItems[i].TransactionType == 3) {
      //    this.LineItemsAmounts.Refund += l_arrResponseLineItems[i].Amount;
      //    this.LineItemsAmounts.OverAllRefund += l_arrResponseLineItems[i].Amount;
      //  }
      //}

      //this.LineItems = l_arrLineItems;
      //this.LineItemsAmounts.Total = this.LineItemsAmounts.Credit;
      //this.LineItemsAmounts.OverAllTotal = this.LineItemsAmounts.OverAllCredit;
      //this.LineItemsAmounts.Paid = l_decOverAllPaidViaBankCashEtc + l_decPaidViaThirdParty;
      //this.LineItemsAmounts.OverAllPaid = l_decOverAllPaidViaBankCashEtc + l_decPaidViaThirdParty;
      //this.LineItemsAmounts.OtherDiscount = l_decOtherDiscountWriteOff;
      //this.LineItemsAmounts.OverAllOtherDiscount = l_decOverAllOtherDiscountWriteOff;

      //this.CalculatePayments();
    }, error => console.error(error));
  }

  public CalculatePayments() {
    var AmountAfterVAT = 0;
    if (this.JobRelatedData.Amounts)
      AmountAfterVAT = this.JobRelatedData.Amounts.PayableAmount;

    AmountAfterVAT = Number((AmountAfterVAT + 0.00001).toFixed(2));

    var Balance = Number(((AmountAfterVAT + this.LineItemsAmounts.Refund) - (this.LineItemsAmounts.Paid + this.LineItemsAmounts.Cancellation)).toFixed(2));

    if (Balance == -0.01 || Balance == 0.01)
      Balance = 0;

    this.LineItemsAmounts.Balance = Balance;
    this.QuoteCalculations.AmountBeforeVAT = this.LineItemsAmounts.Balance / 1.2;
    this.QuoteCalculations.TotalVAT = Balance - this.QuoteCalculations.AmountBeforeVAT;
  }

  public GetQuoteRelatedPaymentAccountDetails(QuoteID) {
    let data: any = {} as any;
    data.QuoteID = QuoteID;
    this.http.post('api/MNVQuote/GetQuoteRelatedPaymentAccountDetails', data).subscribe(result => {
      this.QuoteRelatedPaymentAccount = result[0] as any;
    }, error => console.error(error));
  }

  OpenLineItemsPopup(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => { });
  }

  public TakeOrRefundAmount(p_intType) {
    if (p_intType == -1)
      this.RefundQuoteExtraPayment(p_intType);
    else if (p_intType == 0)
      this.RefundQuoteExtraPayment(p_intType);
    else if (p_intType == 1)
      this.TakeQuoteExtraPaymentConfirmation();
  }

  public RefundQuoteExtraPayment(p_intType) {
    let data = {} as any;
    data.MNVQuoteID = this.QuoteID;
    data.Type = p_intType;
    data.Balance = Math.abs(this.LineItemsAmounts.Balance);
    data.RefundMessage = "";
    if (p_intType == -1)
      data.RefundMessage = "Manual Refund";
    (<HTMLInputElement>document.getElementById('loader')).style.display = 'flex';
    this.http.post('api/MNVQuote/RefundQuoteExtraPayment', data).subscribe(result => {
      (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';
      var l_arrResponse = result[0] as any;
      if (l_arrResponse.Status == 1) {
        this.GetInvoiceDataByQuoteID();
        this.Default.ShowMessage(1, 'Refund has been made successfully');
      }
      else {
        this.Default.ShowMessage(2, l_arrResponse.Message);
      }
    }, error => console.error(error));
  }

  public TakeQuoteExtraPaymentConfirmation() {
    var Message = "Do you want to charge the card for £" + this.LineItemsAmounts.Balance.toFixed(2) + "?";
    Swal.fire({
      title: '',
      text: Message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.TakeQuoteExtraPayment();
      }
    })
  }

  public TakeQuoteExtraPayment() {
    let data = {} as any;
    data.MNVJoID = this.GetMainJobID();
    data.MNVQuoteID = this.QuoteID;
    data.Balance = this.LineItemsAmounts.Balance;
    (<HTMLInputElement>document.getElementById('loader')).style.display = 'flex';
    this.http.post('api/MNVQuote/TakeQuoteExtraPayment', data).subscribe(result => {
      (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';
      var l_arrResponse = result[0] as any;
      if (l_arrResponse.Status == 1) {
        if (l_arrResponse.RequiredAction) {
          this.Default.ShowMessage(4, "The card was declined. This transaction requires further authentication.");
        }
        else {
          if (this.MNVQuote.MNVQuoteStatusID == 0 || this.MNVQuote.MNVQuoteStatusID == 6)
            this.MNVQuote.MNVQuoteStatusID = 1;

          this.GetInvoiceDataByQuoteID();
          this.Default.ShowMessage(1, 'Payment has been taken successfully');
        }
      }
      else {
        if (l_arrResponse.RequiredAction) {
          this.Default.ShowMessage(4, "The card was declined. This transaction requires further authentication.");
        }
        else
          this.Default.ShowMessage(2, l_arrResponse.Message);
      }
    }, error => console.error(error));
  }

  public GetAdditionalCharges() {
    let data = {} as any;
    data.MNVQuoteID = this.QuoteID;
    this.http.post('api/MNVRates/MNVRatesGetDataSetForAdditionalCharges', data).subscribe(result => {
      let Rates = result[0];
      let Tier = "Tier" + this.MNVQuote.TierID + "Add";
      let VanTierRate = Rates.filter(z => z.ObjectTypeID == 1 && z.ObjectID == this.MNVQuote.MNVVanTypeID)[0];
      let HelperTierRate = Rates.filter(z => z.ObjectTypeID == 2 && z.ObjectID == 0)[0];

      let VanAdditionalRate = VanTierRate[Tier];
      let HelperAdditionalRate = HelperTierRate[Tier];

      this.QuoteCalculations.DriverPerStairRate = VanTierRate.PerStairRate;
      this.QuoteCalculations.HelperPerStairRate = HelperTierRate.PerStairRate;

      if (this.MNVQuote.MNVMoverTypeID == 1) {
        this.QuoteCalculations.AdditionalRateAndVAT = VanAdditionalRate;
      }
      if (this.MNVQuote.MNVMoverTypeID == 2) {
        this.QuoteCalculations.AdditionalRateAndVAT = VanAdditionalRate + HelperAdditionalRate
      }
      if (this.MNVQuote.MNVMoverTypeID == 3) {
        this.QuoteCalculations.AdditionalRateAndVAT = VanAdditionalRate + HelperAdditionalRate + HelperAdditionalRate;
      }

      this.CalculateAdditionalTimeAmount(1);
      this.CalculateAdditionalStairsAmount();

    });
  }

  public CalculateAdditionalTimeAmount(p_boolNeedToChange = 0) {

    if (p_boolNeedToChange) {
      if (this.MNVQuote.AdditionalTimeInHours == undefined) {
        if (this.MNVQuote.Mover2ID / 60 >= 1)
          this.MNVQuote.AdditionalTimeInHours = Math.floor(this.MNVQuote.Mover2ID / 60);
      }

      if (this.MNVQuote.AdditionalTimeInMinutes == undefined) {
        if (this.MNVQuote.Mover2ID % 60 >= 1)
          this.MNVQuote.AdditionalTimeInMinutes = Math.floor(this.MNVQuote.Mover2ID % 60);
      }
    }

    var l_intTotalTimeInMinutes = 0;
    if (this.MNVQuote.AdditionalTimeInHours != undefined)
      l_intTotalTimeInMinutes += this.MNVQuote.AdditionalTimeInHours * 60; 

    if (this.MNVQuote.AdditionalTimeInMinutes != undefined)
      l_intTotalTimeInMinutes += this.MNVQuote.AdditionalTimeInMinutes;

    this.MNVQuote.Mover2ID = parseInt(l_intTotalTimeInMinutes.toString());
    this.MNVQuote.AdditionalTimeCharges = (l_intTotalTimeInMinutes / 30) * this.QuoteCalculations.AdditionalRateAndVAT;
  }

  public CalculateAdditionalStairsAmount() {
    this.MNVQuote.DriverAdditionalStairsCharges = this.MNVQuote.Mover3ID * this.QuoteCalculations.DriverPerStairRate;
    this.MNVQuote.HelperAdditionalStairsCharges = this.MNVQuote.Mover3ID * this.QuoteCalculations.HelperPerStairRate;

    if (this.MNVQuote.MNVMoverTypeID == 1)
      this.MNVQuote.AdditionalStairsCharges = this.MNVQuote.DriverAdditionalStairsCharges;
    else if (this.MNVQuote.MNVMoverTypeID == 2)
      this.MNVQuote.AdditionalStairsCharges = this.MNVQuote.DriverAdditionalStairsCharges + this.MNVQuote.HelperAdditionalStairsCharges;
    else if (this.MNVQuote.MNVMoverTypeID == 3)
      this.MNVQuote.AdditionalStairsCharges = this.MNVQuote.DriverAdditionalStairsCharges + (this.MNVQuote.HelperAdditionalStairsCharges * 2);
  }

  public AddAdditionalChargesInputs() {
    let data = {} as any;
    data.MNVQuoteID = this.QuoteID;
    data.Mover2ID = this.MNVQuote.Mover2ID ? this.MNVQuote.Mover2ID : 0;
    data.Mover3ID = this.MNVQuote.Mover3ID ? this.MNVQuote.Mover3ID : 0;

    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/MNVQuote/AddAdditionalChargesInputs', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);
      this.GetQuoteInfoById(false);
    }, error => console.error(error));
  }

  public SaveCharges() {
    var l_decCharges = this.MNVQuote.AdditionalTimeCharges ? this.MNVQuote.AdditionalTimeCharges : 0;
    l_decCharges += this.MNVQuote.AdditionalStairsCharges ? this.MNVQuote.AdditionalStairsCharges : 0;

    var Message = "Do you want to save the charges of £" + l_decCharges.toFixed(2) + " - the last van will charge the clients card";
    if (this.IsThisLastVanToComplete)
      Message = "Do you want to save the charges of £" + l_decCharges.toFixed(2) + "?";
    Swal.fire({
      title: '',
      text: Message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.MNVQuote.AdditionalTimeCharges && this.MNVQuote.AdditionalTimeCharges > 0 && this.MNVQuote.AdditionalStairsCharges && this.MNVQuote.AdditionalStairsCharges > 0) {
          this.AddAdditionalChargesLineItem(1, 0);
          this.AddAdditionalChargesLineItem(2, 1);
        }
        else if (this.MNVQuote.AdditionalTimeCharges && this.MNVQuote.AdditionalTimeCharges > 0)
          this.AddAdditionalChargesLineItem(1);
        else if (this.MNVQuote.AdditionalStairsCharges && this.MNVQuote.AdditionalStairsCharges > 0)
          this.AddAdditionalChargesLineItem(2);
      }
    })
  }

  public ChangeJobStatus(p_intCallPayment = 0) {
    let data = {} as any;
    data.RelMNVQuoteAndMNVMoverID = this.RelMNVQuoteAndMNVMoverID;
    data.Status = 5;
    data.SendReviewEmail = this.SendReviewEmail;
    data.BusinessCard = this.BusinessCard;
    data.MagneticCard = this.MagneticCard;
    data.SocialMediaLeaflet = this.SocialMediaLeaflet;
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/MNVQuote/MNVQuoteUpdateDriverJobStatus', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);
      this.ClosePopupAndAutoSendSms.emit("");
      if (p_intCallPayment > 0)
        this.TakeQuoteExtraPaymentConfirmation();
    }, error => console.error(error));
  }

  public AddAdditionalChargesLineItem(p_intType, p_boolIsLast = 1) {
    if (p_intType == 1) {
      var l_strReference = "Additional Time";
      var l_strTitle = "Additional " + this.Default.GetDurationTextForSeconds(this.MNVQuote.Mover2ID * 60) + " charge" + this.VanText;
      this.AjaxSaveLineItem(1, this.MNVQuote.AdditionalTimeCharges, l_strTitle, l_strReference, this.QuoteID, 0, p_boolIsLast);
    }
    else if (p_intType == 2 && this.MNVQuote.AdditionalStairsCharges > 0) {
      var l_strReference = "Additional Stairs";
      var l_strTitle = "Additional " + this.MNVQuote.Mover3ID + (this.MNVQuote.Mover3ID > 1 ? " Stairs charge" : " Stair charge") + this.VanText;
      this.AjaxSaveLineItem(1, this.MNVQuote.AdditionalStairsCharges, l_strTitle, l_strReference, this.QuoteID, 0, p_boolIsLast);
    }
    else if (p_intType == 2 && this.MNVQuote.AdditionalStairsCharges < 0) {
      var l_strReference = "Additional Stairs";
      var l_strTitle = "Additional " + this.MNVQuote.Mover3ID + (Math.abs(this.MNVQuote.Mover3ID) > 1 ? " Stairs charge" : " Stair charge") + this.VanText;
      this.AjaxSaveLineItem(3, this.MNVQuote.AdditionalStairsCharges, l_strTitle, l_strReference, this.QuoteID, 0, p_boolIsLast);
    }
  }

  public AjaxSaveLineItem(Type, Amount, Note, RefID, QuoteID, MarkPaid, p_boolIsLast) {
    let data = {} as any;
    data.Type = Type;
    data.Amount = Amount;
    data.Note = Note;
    data.RefID = RefID;
    data.MNVJobID = this.GetMainJobID();
    data.QuoteID = this.QuoteID;
    if (this.MNVQuote.ParentID > 0)
      data.QuoteID = this.MNVQuote.ParentID;
    data.MarkPaid = MarkPaid;
    data.IsDriverSide = true;
    data.LineItemType = 1;

    data.RefID = Note;

    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/MNVQuote/AjaxSaveLineItem', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);

      var StatusData = result[0][0];
      if (StatusData.Status == 1 && p_boolIsLast) {
        this.Default.ShowMessage(1, 'Line item has been added successfully.');

        this.AddAdditionalChargesInputs();

        this.GetInvoiceDataByQuoteID();

        this.LoadAllQuotesLinkedToThisJob(this.GetMainJobID());

        this.MNVQuote.QuoteLoadingTime = this.Default.addMinutes(new Date(), 0.016 * 2);

        this.CheckIfAllTheOtherVansAreCompleted(1);
      }
      else if (p_boolIsLast) {
        this.Default.ShowMessage(2, StatusData.Message);
      }

    });
  }

  public DeleteLineItem(PTPaymentInvoiceLineItemID) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.AjaxDeleteLineItem(PTPaymentInvoiceLineItemID);
      }
    })
  }

  public AjaxDeleteLineItem(PTPaymentInvoiceLineItemID) {
    let data = {} as any;
    data.QuoteID = this.GetMainJobID();
    data.MNVServiceID = this.QuoteID;
    if (this.MNVQuote.ParentID > 0)
      data.MNVServiceID = this.MNVQuote.ParentID;
    data.PTPaymentInvoiceLineItemID = PTPaymentInvoiceLineItemID;
    data.IsDriverSide = true;
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/MNVQuote/AjaxDeleteLineItem', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);

      var l_arrResponse = result[0][0] as any;

      if (l_arrResponse.Status == 1) {
        this.AddAdditionalChargesInputs();
        this.GetInvoiceDataByQuoteID();
        this.LoadAllQuotesLinkedToThisJob(this.GetMainJobID());
        this.Default.ShowMessage(1, 'Line item has been deleted successfully.');
      }
      else {
        this.Default.ShowMessage(2, l_arrResponse.Message);
      }
    }, error => console.error(error));
  }

  public ClearPaymentInfo() {

    this.Error.CardName = true;
    this.Error.CardNumber = true;
    this.Error.ExpYear = true;
    this.Error.ExpMonth = true;
    this.Error.CVV = true;

    //this.PaymentObject.CardName = 'Atif Paracha';
    //this.PaymentObject.CardNumber = '5555555555554444';
    //this.PaymentObject.ExpYear = 23;
    //this.PaymentObject.ExpMonth = 4;
    //this.PaymentObject.CVV = '123';

    this.PaymentObject.CardName = '';
    this.PaymentObject.CardNumber = '';
    this.PaymentObject.ExpYear = undefined;
    this.PaymentObject.ExpMonth = undefined;
    this.PaymentObject.CVV = '';
  }

  public SavePaymentMethod() {
    // check validation
    this.UpdateQuotePaymentMethod(this.QuoteID, this.PaymentObject.CardName, this.PaymentObject.CardNumber, this.PaymentObject.ExpYear, this.PaymentObject.ExpMonth, this.PaymentObject.CVV);
  }

  public UpdateQuotePaymentMethod(QuoteID, CardName, CardNumber, ExpYear, ExpMonth, CVV) {
    let data: any = {} as any;
    data.MNVQuoteID = QuoteID;
    data.NameOnCard = CardName;
    data.CreditCard = CardNumber;
    data.Year = ExpYear;
    data.Month = ExpMonth;
    data.Cvv = CVV;

    this.Error.CardName = true;
    this.Error.CardNumber = true;
    this.Error.ExpYear = true;
    this.Error.ExpMonth = true;
    this.Error.CVV = true;

    var IsOkay = true;

    if (!this.PaymentObject.CardName || this.PaymentObject.CardName == "") {
      IsOkay = false;
      this.Error.CardName = false;
    }

    if (!this.PaymentObject.CardNumber || this.PaymentObject.CardNumber == "") {
      IsOkay = false;
      this.Error.CardNumber = false;
    }

    if (!this.PaymentObject.ExpYear || this.PaymentObject.ExpYear == "") {
      IsOkay = false;
      this.Error.ExpYear = false;
    }

    if (!this.PaymentObject.ExpMonth || this.PaymentObject.ExpMonth == "") {
      IsOkay = false;
      this.Error.ExpMonth = false;
    }

    if (!this.PaymentObject.CVV || this.PaymentObject.CVV == "") {
      IsOkay = false;
      this.Error.CVV = false;
    }

    if (IsOkay) {

      this.Default.SetMasterAnimationV2(true);
      this.http.post('api/MNVQuote/UpdateQuotePaymentMethod', data).subscribe(result => {
        this.Default.SetMasterAnimationV2(false);
        var l_arrResponse = result[0] as any;
        if (l_arrResponse.Status == 1) {
          //if status is okay then call below function
          this.GetQuoteRelatedPaymentAccountDetails(QuoteID);
          (<HTMLInputElement>document.getElementById("close")).click();
          this.Default.ShowMessage(1, 'Payment method has been updated successfully.');
        }
        else {
          this.Default.ShowMessage(2, l_arrResponse.Message);
        }
      }, error => console.error(error));
    }
  }

  public CheckIfAllTheOtherVansAreCompleted(p_boolChangeStatus) {
    this.IsThisLastVanToComplete = false;
    let data = {} as any;
    data.MNVJoID = this.GetMainJobID();
    data.MNVQuoteID = this.QuoteID;
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/MNVQuote/CheckIfAllTheOtherVansAreCompleted', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);
      var l_arrResponse = result[0] as any;
      if (l_arrResponse.Status == 1) {
        this.IsThisLastVanToComplete = true;
      }

      if (p_boolChangeStatus && !this.IsThisLastVanToComplete)
        this.ChangeJobStatus();
    }, error => console.error(error));
  }

  OpenSendPaymentLinkPopup(content) {
    this.ClientData.EnteredEmailAddress = this.ClientData.EmailAddress;
    this.ClientData.EnteredMobile = this.ClientData.Mobile;

    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        
      }, (reason) => {
        
      });
  }

  public SendPaymentLink(TypeID) {
    if ((TypeID == 1 && this.ClientData.EmailAddress != "") || (TypeID == 2 && this.ClientData.Mobile != "") || (TypeID == 3 && this.ClientData.EmailAddress != "" && this.ClientData.Mobile != "")) {
      let data = {} as any;
      data.MNVJobID = this.GetMainJobID();
      data.EmailAddress = this.ClientData.EmailAddress;
      data.Mobile = this.ClientData.Mobile;
      data.EnteredEmailAddress = this.ClientData.EnteredEmailAddress;
      data.EnteredMobile = this.ClientData.EnteredMobile;
      data.TypeID = TypeID;
      data.Amount = Number((this.LineItemsAmounts.Balance / 1.2).toFixed(2));
      data.VAT = Number((this.LineItemsAmounts.Balance - data.Amount).toFixed(2));
      this.Default.SetMasterAnimationV2(true);
      this.http.post('api/MNVQuote/SendPaymentLink', data).subscribe(result => {
        this.Default.SetMasterAnimationV2(false);
        var l_arrResponse = result[0][0] as any;
        (<HTMLInputElement>document.getElementById("close")).click();
        if (l_arrResponse.Status == 1) {
          this.Default.ShowMessage(1, 'Link has been sent.');
        }
        else {
          this.Default.ShowMessage(2, l_arrResponse.Message);
        }
      }, error => console.error(error));
    }
    else {
      this.Default.ShowMessage(2, "Please enter the valid mobile number and email.");
    }
  }

  public GetMainJobID() {
    return this.MNVQuote.MNVJobID;
  }

}
