<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8" />
    <title></title>
</head>
<body>
    <a id="ShowModal2" data-toggle="modal" data-target="#myModal2"></a>
    <a id="aresourcesAvailability" data-toggle="modal" data-target="#resourcesAvailability"></a>

    <!--MM:: Modal For Events Detail-->
    <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title" id="myModalLabel">Details</h4>
                </div>
                <div id="modalBody" class="modal-body">
                    <div *ngIf="m_arrFormattedEvents.length > 0">
                        <ng-container *ngFor="let record of m_arrFormattedEvents;let index=index">
                            <div>
                                <span>
                                    {{record}}
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!--MM:: Modal For Resources availability-->
    <div class="modal fade" id="resourcesAvailability" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title" id="myModalLabel">{{ModalHeading}}</h4>
                </div>
                <div class="modal-body">
                    <table class="table table-hover table-striped table-bordered table-condensed ResourceCount">
                        <thead>
                            <tr>
                                <th>Resource</th>
                                <th>Available</th>
                                <th>Not Available</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let record of AvailableResourcesType;let index=index">
                                <tr>
                                    <td>
                                        <span *ngIf="record.Quantity > 0">
                                            {{record.Title}}
                                        </span>
                                        <span *ngIf="record.Quantity < 1 && NonAvailableResourcesType[index].Quantity > 0">
                                            {{record.Title}}
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="record.Quantity > 0">
                                            {{record.Quantity}}
                                            <i class="fa fa-info AdminTooltip" (mouseenter)="ShowToolTipData(record,true)" title="{{TooltipData}}" style="float: right;margin: 3px 5px 0 0;"></i>
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="NonAvailableResourcesType[index].Quantity > 0">
                                            {{NonAvailableResourcesType[index].Quantity}}
                                            <i class="fa fa-info AdminTooltip" (mouseenter)="ShowToolTipData(NonAvailableResourcesType[index],false)" title="{{TooltipData}}"></i>
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td>Total</td>
                                <td>
                                    {{TotalAvailable}}
                                    <i class="fa fa-info AdminTooltip" (mouseenter)="ShowToolTipData(0,true)" title="{{TooltipData}}"></i>
                                </td>
                                <td>
                                    {{TotalNonAvailable}}
                                    <i class="fa fa-info AdminTooltip" (mouseenter)="ShowToolTipData(0,false)" title="{{TooltipData}}"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</body>
</html>
