import { Component, Inject, PLATFORM_ID } from '@angular/core';

import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

  @Inject(PLATFORM_ID) private platformId: Object;

  parse(url: string): UrlTree {
    if (isPlatformBrowser(this.platformId)) {
      var l_strBase = window.location.pathname;
      var l_strSearch = window.location.search;
      l_strBase = l_strBase.toLowerCase();
      url = l_strBase + l_strSearch;
      return super.parse(url);
    } else {
      return super.parse(url);
    }
  }
}
