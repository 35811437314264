<!--<h5>Calculate the additional amount to charge.</h5>-->
<div class="comp-1 sm--flt">
  <div class="comp-2">
    <div class="comp-3">
      <label>Additional Time in Hours</label>
      <input type="number" class="cs-form__field" [(ngModel)]="MNVQuote.AdditionalTimeInHours" (keyup)="CalculateAdditionalTimeAmount();" />
    </div>
    <div class="comp-3">
      <label>Additional Time in Minutes</label>
      <input type="number" class="cs-form__field" [(ngModel)]="MNVQuote.AdditionalTimeInMinutes" (keyup)="CalculateAdditionalTimeAmount();" />
    </div>
    <div class="comp-3">
      <label>Additional Time Charges: £{{MNVQuote.AdditionalTimeCharges | number:'1.2-2'}}</label>
      <!--<button type="button" class="btn btn-primary" (click)="AddAdditionalChargesLineItem(1);">Add Additional Time Charges</button>-->
    </div>
  </div>
  <div class="comp-2">
    <div class="comp-3">
      <label>Additional Stairs</label>
      <input type="number" class="cs-form__field" [(ngModel)]="MNVQuote.Mover3ID" (keyup)="CalculateAdditionalStairsAmount();" />
    </div>
    <div class="comp-3">
      <label>Additional Stairs Charges: £{{MNVQuote.AdditionalStairsCharges | number:'1.2-2'}}</label>
      <!--<button type="button" class="btn btn-primary" (click)="AddAdditionalChargesLineItem(2);">Add Additional Stairs Charges</button>-->
    </div>
  </div>
</div>
<div class="ib-display stw100 text-center sm--flt" *ngIf="(MNVQuote.AdditionalTimeCharges || MNVQuote.AdditionalStairsCharges)">
  <button class="btn btn-primary save--sm" (click)="SaveCharges()">
    &#43; Save Additional Charges
  </button>
</div>
<div class="ib-display stw100 sm--flt">
  <ng-container *ngFor="let LineItem of LineItems">
    <ng-container>
      <div class="comp-6">
        <span class="comp-7">
          {{LineItem.RefferenceNumber}}:
        </span>
        <span class="comp-8">£{{LineItem.Amount | number:'1.2-2'}}</span>
        <a *ngIf="LineItem.CanDelete" (click)="DeleteLineItem(LineItem.PTPaymentInvoiceLineItemID)"><i class="far fa-trash-alt"></i></a>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="comp-4 sm--flt">
  <div>
    <b>Amount: </b> £{{QuoteCalculations.AmountBeforeVAT | number:'1.2-2'}}
  </div>
  <div>
    <b>VAT: </b> £{{QuoteCalculations.TotalVAT | number:'1.2-2'}}
  </div>
  <div>
    <b>Balance: </b> £{{LineItemsAmounts.Balance | number:'1.2-2'}}
  </div>
</div>

<div class="ib-display stw100 sm--flt2 sm-review-mail" style="width: 195px; margin: 0 auto; display: block;">
  <div class="flex-display" style="margin: 0 0 15px 0;justify-content: flex-start;font-size: 16px;">
    <input type="checkbox" [(ngModel)]="SendReviewEmail" style="width: 18px;height: 18px;margin: 0 5px 0 0;">
    <span class="ins-2">Email for Review</span>
  </div>
</div>

<div class="ib-display stw100 sm--flt2">
  <div class="flex-display" style="margin: 0 0 15px 0; justify-content: center; font-size: 16px;">
    <span>Have you given the client a:</span>
  </div>
</div>

<div class="ib-display stw100 sm--flt2" style="width: 195px; margin: 0 auto; display: block; ">
  <div class="flex-display" style="margin: 0 0 15px 0; justify-content: flex-start; font-size: 16px;">
    <input type="checkbox" [(ngModel)]="BusinessCard" style="width: 18px;height: 18px;margin: 0 5px 0 0;">
    <span class="ins-2">Business Card</span>
  </div>
</div>

<div class="ib-display stw100 sm--flt2" style="width: 195px; margin: 0 auto; display: block;">
  <div class="flex-display" style="margin: 0 0 15px 0; justify-content: flex-start; font-size: 16px;">
    <input type="checkbox" [(ngModel)]="MagneticCard" style="width: 18px;height: 18px;margin: 0 5px 0 0;">
    <span class="ins-2">Magnetic Card</span>
  </div>
</div>

<div class="ib-display stw100 sm--flt2" style="width: 195px; margin: 0 auto; display: block; ">
  <div class="flex-display" style="margin: 0 0 15px 0; justify-content: flex-start; font-size: 16px;">
    <input type="checkbox" [(ngModel)]="SocialMediaLeaflet" style="width: 18px;height: 18px;margin: 0 5px 0 0;">
    <span class="ins-2">Social Media Leaflet</span>
  </div>
</div>

<div class="card-footer card-footer-2 card-footer-3">
  <div class="col-md-12 text-right ButtonSpaces detail-btns">
    <!--<button *ngIf="QuoteRelatedPaymentAccount.length>0 && LineItemsAmounts.Balance < 0" class="btn btn-primary" (click)="TakeOrRefundAmount(0)">
      Refund
    </button>-->
    <!--<button *ngIf="QuoteRelatedPaymentAccount.length>0 && LineItemsAmounts.Balance > 0" class="btn btn-primary" (click)="ChangeJobStatus(1)">
      Mark as Completed and Charge
    </button>-->

    <ng-container *ngIf="IsThisLastVanToComplete">
      <button *ngIf="QuoteRelatedPaymentAccount.length>0 && LineItemsAmounts.Balance > 0" class="btn btn-primary" (click)="TakeOrRefundAmount(1)">
        &#163; Charge Card
      </button>

      <button *ngIf="LineItemsAmounts.Balance > 0" class="btn btn-primary" (click)="OpenSendPaymentLinkPopup(sendpaymentlink)">
        &#43; Send New Card Payment Link
      </button>

      <!--<button *ngIf="QuoteRelatedPaymentAccount.length>0" class="btn btn-primary" (click)="OpenLineItemsPopup(addpaymentmethods)">
        &#60;&#62; Update Payment Method
      </button>

      <button *ngIf="QuoteRelatedPaymentAccount.length==0" class="btn btn-primary" (click)="OpenLineItemsPopup(addpaymentmethods)">
        &#43; Add Payment Method
      </button>-->

    </ng-container>

    <button class="btn btn-primary" (click)="ChangeJobStatus()">
      &#x2713; Mark as Completed
    </button>
  </div>
</div>


<ng-template #addpaymentmethods let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-addpaymentmethods">
      Add Payment Method
    </h4>

    <button type="button" class="btn btn-dark" id="close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="outline:none !important;">

      <span aria-hidden="true">
        x
      </span>
    </button>
  </div>
  <div class="modal-body">

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <div class="panel panel-default">
            <div class="panel-body">

              <ng-container>
                <div class="form-group">
                  <label for="cardNumber">
                    CARD NUMBER
                  </label>
                  <div class="input-group">
                    <input type="text" class="cs-form__field form-control" [(ngModel)]="PaymentObject.CardNumber" [ngClass]="Error.CardNumber  == true? '':'RedBorderClass2'" placeholder="Valid Card Number" />
                    <span class="input-group-addon"><span class="glyphicon glyphicon-lock"></span></span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="cardNumber">
                    Name
                  </label>
                  <div class="input-group">
                    <input type="text" class="cs-form__field form-control" [(ngModel)]="PaymentObject.CardName" [ngClass]="Error.CardName  == true? '':'RedBorderClass2'" placeholder="Name" />
                    <span class="input-group-addon"><span class="glyphicon glyphicon-lock"></span></span>
                  </div>
                </div>
                <div class="cs-height__15 cs-height__lg__15"></div>
                <div class="row">
                  <div class="col-xs-12 col-md-12">
                    <div class="form-group">
                      <label for="expityMonth">
                        EXPIRY DATE
                      </label>
                      <div class="ib-display stw100">
                        <div style="display:grid;grid-template-columns:1fr 1fr; column-gap:15px;">
                          <input type="number" class="cs-form__field form-control" placeholder="MM" [(ngModel)]="PaymentObject.ExpMonth" [ngClass]="Error.ExpMonth  == true? '':'RedBorderClass2'" />
                          <input type="number" class="cs-form__field form-control" placeholder="YY" [(ngModel)]="PaymentObject.ExpYear" [ngClass]="Error.ExpYear  == true? '':'RedBorderClass2'" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cs-height__15 cs-height__lg__15"></div>
                  <div class="col-xs-12 col-md-12 pull-right">
                    <div class="form-group">
                      <label for="cvCode">
                        CV CODE
                      </label>
                      <input type="password" class="cs-form__field form-control" placeholder="CV" [(ngModel)]="PaymentObject.CVV" [ngClass]="Error.CVV  == true? '':'RedBorderClass2'" />
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="cs-height__15 cs-height__lg__15"></div>
          <ul class="nav nav-pills nav-stacked">
          </ul>
          <div style="display:flex;align-items:center;justify-content:center;">
            <button type="button" class="btn btn-dark" id="close"
                    aria-label="Close" (click)="modal.dismiss('Cross click')" style="outline:none !important;display:none">
            </button>
            <a class="btn btn-dark" role="button" style="width:100px;" (click)="SavePaymentMethod();">Save</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #sendpaymentlink let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-sendpaymentlink">
      Send Payment Link
    </h4>
    <button type="button" class="btn btn-dark" id="close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="outline:none !important;">
      <span aria-hidden="true">x</span>
    </button>
  </div>

  <div class="modal-body p-0">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col-xs-12 col-md-12 p-0">
          <div class="panel panel-default">
            <div class="panel-body">
              <span class="DataBoxes col-xs-12 col-sm-6 col-md-3 col-lg-3 ib-display" style="width:100% !important;margin-top:20px;">
                <span class="ColumnName">Email</span>
                <span>
                  <input type="text" class="form-control" [(ngModel)]="ClientData.EnteredEmailAddress" />
                </span>
                <span class="ColumnName">Mobile</span>
                <span>
                  <input type="text" class="form-control" [(ngModel)]="ClientData.EnteredMobile" />
                </span>
              </span>
              <div class="save-new-client p-3">
                <a class="btn btn-primary" (click)="SendPaymentLink(1);">Email</a>
                <a class="btn btn-primary" style="margin-right:7px;margin-left:7px;" (click)="SendPaymentLink(2);">SMS</a>
                <a class="btn btn-primary" (click)="SendPaymentLink(3);">SMS And Email</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
