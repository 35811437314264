import { HttpClient } from '@angular/common/http';
import { ViewChild, Inject, PLATFORM_ID, Component } from '@angular/core';
import { DefaultTS } from '../SharedComponents/Default';
import { isPlatformBrowser } from '@angular/common';
import { } from 'googlemaps'
import { QuoteService } from '../Quote/quote.service';
import { Router } from '@angular/router';
// Public Page: Accessible to every visitor and crawlers
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  @ViewChild('map', { static: true }) mapElement: any;
  map: google.maps.Map;
  data = [];
  keyword = 'address';
  public ShowQuote = false;
  public FromPostalCode: string;
  public ToPostalCode: string;
  public faqs = {} as any;
  public UserReview = [];
  public TotalRating = 0;
  public AggregateRatingUserReview = [];
  public AggregateRatingTotalRating = 0;
  public pagination = [] as any;
  public currentPage = 1;
  public itemsPerPage = 1;


  constructor(public http: HttpClient, public service: QuoteService, private route: Router, private Default: DefaultTS, @Inject(PLATFORM_ID)
  private platformId: Object) {
    this.Default.IsPorter = false;

    var l_strQueryResult = this.Default.getParamValueQueryStringV2("msg");
    if (l_strQueryResult == "1")
      this.Default.ShowMessage(4, "We have received your message and endeavour to answer you as soon as possible.");

    this.GetAllReferences();
    this.GetAllUserReview();


    if (isPlatformBrowser(this.platformId)) {
      const markerArray: google.maps.Marker[] = [];

      const mapProperties = {
        center: new google.maps.LatLng(40.771, -73.974),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
    }
    this.faqs.question1 = true;
    this.faqs.question2 = false;
    this.faqs.question3 = false;
    this.faqs.question4 = false;
    this.faqs.question5 = false;
  }
  getAddress(code) {
    this.service.AddressAutoComplete(code).subscribe(result => {
      this.data = result['suggestions'];
    }, error => {
      console.log(error);
    });
  }
  getAddressBreakup(key, number) {
    this.service.GetAddressById(key).subscribe(result => {
      if (number == 1) {
        this.service.fromDetail = result;
      } else {
        this.service.toDetail = result;
      }
    }, error => {
      console.log(error);
    });
  }

  GetDirections() {
    const directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer();
    const markerArray: google.maps.Marker[] = [];
    var stepDisplay: google.maps.InfoWindow;

    directionsService.route({
      origin: (<HTMLInputElement>document.getElementById('from')).value,
      destination: (<HTMLInputElement>document.getElementById('to')).value,
      travelMode: google.maps.TravelMode.DRIVING,
      avoidTolls: true,
    }, function (response, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        (<HTMLInputElement>document.getElementById('distance')).innerHTML = response.routes[0].legs[0].distance.text;
        (<HTMLInputElement>document.getElementById('time')).innerHTML = response.routes[0].legs[0].duration.text
        //this.directions = response.routes[0].legs[0].distance;
        console.log(response);
        for (var i = 0; i < response.routes[0].legs[0].steps.length; i++) {
          if (response.routes[0].legs[0].steps[i].instructions.includes("toll")) {
            console.log("congestion");
          }
        }
        //directionsDisplay.setDirections(response);
        ///*directionsDisplay.setMap()*/
        //(document.getElementById("warnings-panel") as HTMLElement).innerHTML =
        //  "<b>" + response.routes[0].warnings + "</b>";
        //directionsDisplay.setPanel(document.getElementById('directionsList'));

        //this.showSteps(response, markerArray, stepDisplay, this.map);
      } else {
        alert('Google route unsuccesfull!');
      }
    });

    //const directionsService = new google.maps.DirectionsService();
    //const request = {
    //  origin: 'Disneyland',
    //  destination: 'Universal Studios Hollywood',
    //  travelMode: 'DRIVING'
    // };

    // const response = directionsService.route(request, () => { });

    //const headers = new HttpHeaders;

    //headers.append('Access-Control-Allow-Origin', '*');
    //this.http.get("https://maps.googleapis.com/maps/api/directions/json?origin=Lahore,Punjab,Pakistan&destination=Islamabad,IslamabadCapitalTerritory,Pakistan&key=AIzaSyA-jNxRpB-l8j1RGA-ALQPBXG3o4hLMNiM", { headers: headers }).subscribe(result => {
    //  console.log(result);
    //}, error => { console.log(error) });
  }

  public attachInstructionText(
    stepDisplay: google.maps.InfoWindow,
    marker: google.maps.Marker,
    text: string,
    map: google.maps.Map
  ) {
    google.maps.event.addListener(marker, "click", () => {
      // Open an info window when the marker is clicked on, containing the text
      // of the step.
      stepDisplay.setContent(text);
      stepDisplay.open(map, marker);
    });
  }
  GoToQuote() {
    this.route.navigate(['/quotes']);
  }
  onFromSelected(item) {
    this.data = [];
    this.getAddressBreakup(item.id, 1);
    this.service.from = item.address;
  }
  public fromSelected;
  public toSelected;

  onToSelected(item) {
    this.data = [];
    this.getAddressBreakup(item.id, 2);
    this.service.to = item.address;
  }
  public from = [] as any;
  public to = [] as any;

  onFromChangeSearch(val: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('myQuery');
      localStorage.removeItem('myQuote');
    }
    this.service.AddressAutoComplete(val).subscribe(res => {
      this.from = res['suggestions'];
      if (this.from != null) {
        for (var i = 0; i < this.from.length; i++) {
          this.from[i].address += ',' + ' ' + val;
        }
      }
    })
  }
  onToChangeSearch(val: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('myQuery');
      localStorage.removeItem('myQuote');
    }
    this.service.AddressAutoComplete(val).subscribe(res => {
      this.to = res['suggestions'];
      if (this.to != null) {
        for (var i = 0; i < this.to.length; i++) {
          this.to[i].address += ',' + ' ' + val;
        }
      }
    })
  }
  scrolltoLeft() {
    document.getElementById('container').scrollLeft -= 200;
  }
  scrolltoRight() {
    document.getElementById('container').scrollLeft += 200;
  }

  public GetAllUserReview() {
    this.http.get(this.Default.BaseURL + 'api/UserReview/GetTopFiveUserReview').subscribe(result => {
      this.UserReview = result[0];
      this.TotalRating = result[1][0].TotalRating;
      this.Default.PageStatusChecks[0] = 'Status1:OK';
      this.pagination = [] as any;
      this.pagination = this.UserReview.slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage)

    }, error => console.error(error));
  }

  public LoadNextReview() {
    this.currentPage = this.currentPage + 1;
    if (this.currentPage == 6)
      this.currentPage = 1;
    this.pagination = this.UserReview.slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage);
  }

  public LoadPreviousReview() {
    this.currentPage = this.currentPage - 1
    this.pagination = this.UserReview.slice((this.currentPage - 1) * this.itemsPerPage, (this.currentPage) * this.itemsPerPage);
  }

  public GetAllReferences() {
    this.http.get(this.Default.BaseURL + 'api/UserReview/UserReviewGetCompleteDataSet').subscribe(result => {
      this.AggregateRatingUserReview = result[0];
      this.AggregateRatingTotalRating = result[1][0].TotalRating;
    }, error => console.error(error));
  }


}
