<!--<MetaData [PageTitle]="" [PageDescription]="" [PageKeyWords]="" [PageID]="149"></MetaData>-->
<div class="cs-height__150 cs-height__lg__70" style="height:140px;"></div>
<ng-container *ngIf="!Default.IsUserLoggedIn">
  <div class="hide-980-back" style="display: flex; justify-content: center; min-height: 571px; height: calc(100vh - 240px); background-image: url(https://www.finestvan.co.uk/images/login-left.png),url(https://www.finestvan.co.uk/images/login-right.png); background-position: left calc(100% - 17px),calc(100% - 113px) bottom; background-repeat: no-repeat,no-repeat;">
    <div class="container" style="max-width: 301px; padding: 0;">
      <div class="cs-form cs-style2">

        <!--<a (click)="SendSMS()" style="color:#482064;">Send SMS</a>-->
        <div class="row" *ngIf="!AddVerificationCode">
          <h1 style="font-weight:bold;text-align:center;font-size:37px;line-height:46px;margin-bottom:20px;">Log In</h1>
          <div class="col-sm-12">
            <input #emailRef type="email" id="inputEmail" style="box-shadow: none; border: 1px solid #ccc; background: #f9f9f9; letter-spacing: 1px; height: 45px; border-radius: 5px; padding: 0 16px; font-size: 16px;" [(ngModel)]="UserName" class="cs-form__field" placeholder="Email" (keyup)="LoginValidation(false)" [ngModelOptions]="{standalone: true}" required autofocus>
            <div class="cs-height__30 cs-height__lg__30"></div>
          </div>
          <div class="col-sm-12">
            <input type="password" id="inputPassword" style="box-shadow: none; border: 1px solid #ccc; background: #f9f9f9; letter-spacing: 1px; height: 45px; border-radius: 5px; padding: 0 16px; font-size: 16px;" class="cs-form__field" [(ngModel)]="Password" placeholder="Password" (keyup)="LoginValidation(false)" [ngModelOptions]="{standalone: true}" required>
            <div class="cs-height__50 cs-height__lg__50"></div>
          </div>
          <div class="col-sm-6">

            <span></span>
          </div>

          <div style="display:flex;justify-content:center;">
            <button class="cs-btn cs-style1 cs-color1 cs-primary__font w-100 btn-login-new" type="submit" (click)="CallLogin=true;LoginValidation(true)" style="width: 140px !important; min-width: 140px !important; border: none !important; font-size: 16px; line-height: 18px; font-weight: bold; height: 50px; background: #64278c;">Log In</button>
          </div>
          <div style="display:flex;justify-content:center;margin:30px 0 0 0;">
            <a [routerLink]="['/forgotpassword']" style="color:#64278c;font-size:16px;line-height:26px;text-decoration:underline;">Forgotten Password?</a>

          </div>
          <div style="display:flex;justify-content:center;margin:48px 0 0 0;">
            <span style="font-size: 16px; line-height: 22px; color: #263a5d; white-space:nowrap;">If you dont have a login yet, you can register here.</span>

          </div>
          <div style="display:flex;justify-content:center;margin:15px 0 0 0">
            <a class="cs-btn cs-style1 cs-color2 cs-primary__font w-100 login-new-2" href="/signup" style="width: 140px !important; min-width: 140px !important; border: none !important; font-size: 16px; line-height: 18px; font-weight: bold; height: 50px; background: none; border: 1px solid #64278c !important;">Register</a>
          </div>
          <!--<button style="display:none;" class="cs-btn cs-style1 cs-color1 cs-primary__font w-100" type="submit" (click)="SendMessage(1)">Send Test Email</button>
          <button style="display:none;" class="cs-btn cs-style1 cs-color1 cs-primary__font w-100" type="submit" (click)="SendMessage(2)">Send Test Message</button>-->

        </div>
        <div class="row" *ngIf="AddVerificationCode">
          <h1 style="font-weight:bold;text-align:center;font-size:27px;line-height:36px;margin-bottom:20px;">Enter Verification Code</h1>
          <div class="col-sm-12">
            <input type="text" id="inputPasscode" style="box-shadow: none; border: 1px solid #ccc; background: #f9f9f9; letter-spacing: 1px; height: 45px; border-radius: 5px; padding: 0 16px; font-size: 16px;" [(ngModel)]="Passcode" class="cs-form__field" placeholder="Passcode" (keyup)="$event.keyCode == 13 ? CheckPasscodeAndLogin(1): CheckPasscodeAndLogin(0)" />
            <div class="cs-height__30 cs-height__lg__30"></div>
          </div>


          <div style="display:flex;justify-content:center;">
            <button class="cs-btn cs-style1 cs-color1 cs-primary__font w-100 btn-login-new" style="width: 140px !important; min-width: 140px !important; border: none !important; font-size: 16px; line-height: 18px; font-weight: bold; height: 50px; background: #64278c;" type="submit" (click)="CheckPasscodeAndLogin(1)">Proceed</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>

