<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8" />
    <title></title>
</head>
<body>
    <div class="AssessmentTimer">
        <span id="demo"></span>
    </div>
    <span hidden id="TimeSpent"></span>
</body>
</html>