<!-- Button trigger modal -->
<a id="btnConfirmationPopup" data-toggle="modal" data-target="#mdlConfirmationPopup"></a>
<!-- Modal -->
<div class="modal fade" id="mdlConfirmationPopup" tabindex="-1" aria-labelledby="mdlConfirmationPopup" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                <button type="button" class="close btn btn-primary" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <span id="ConfirmationModalMessage" class="deletecard" [innerHTML]="ConfirmtionMessage">{{ConfirmtionMessage}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="ConfirmStatus(1);">{{ConfirmtionConfirmText}}</button>
                <button type="button" class="btn btn-primary" (click)="ConfirmStatus(0);">{{ConfirmtionCancelText}}</button>
            </div>
        </div>
    </div>
</div>
