import { Component, Inject, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { DefaultTS } from '../SharedComponents/Default';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FilterJsonData } from '../Pipes/FilterPipe';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-ConfirmationPopup',
  templateUrl: './ConfirmationPopup.html'
})
export class ConfirmationPopup {
  public http: HttpClient;
  public baseUrl: string;
  @Input() public ConfirmtionMessage = '';
  @Input() public ConfirmtionCancelText = '';
  @Input() public ConfirmtionConfirmText = '';
  @Input() public ConfirmtionType = 0;
  @Output() ResponseFromConfirmation: EventEmitter<any> = new EventEmitter();
  @Output() SetMyValue: EventEmitter<any> = new EventEmitter();

  constructor(http: HttpClient, private router: Router, private cd: ChangeDetectorRef, private zone: NgZone,
    private router2: ActivatedRoute, private Default: DefaultTS, private sanitized: DomSanitizer) {
    this.http = http;
  }

  ngOnChanges() {
  }

  public ShowConfirmtionMessage() {
    debugger;
     document.getElementById("btnConfirmationPopup")!.click();
  }
  public CloseConfirmtionMessage() {
     document.getElementById("btnConfirmationPopup")!.click();
  }

  public ConfirmStatus(Status: number) {
    let data = {} as any;
    data.Status = Status;
    data.ConfirmtionType = this.ConfirmtionType;
    this.ResponseFromConfirmation.emit(data);
    this.CloseConfirmtionMessage();
  }
}
