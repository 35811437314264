<div class="post_upload channel_uploadsm">

  <div class="containerupload" *ngIf="CanEditFiles && PTFilesList.length < MaxNumberOfFiles" appDnd (fileDropped)="OnPTFileDropped($event)">
    <input type="file" #PTFileUploaderInput id="PTFileUploaderInput" multiple (change)="PTFileBrowseHandler($event.target.files)" />
    <img src="assets/img/dnd/ic-upload-file.svg" alt="Upload" style="opacity:0;">
    <h3 style="color: #000000 !important;font-size: 16px !important;">Drag and drop file here</h3>
    <h3 style="color: #000000 !important;font-size: 16px !important;">or</h3>
    <label for="fileDropRef" class="brws" style="padding-top:6px;">Browse for file</label>
  </div>

  <ng-container *ngFor="let img of PTFilesList;let index=index">

    <div class="post_img" style=" margin-top: 10px; margin-right: 10px;width:100px;height:100px;overflow:hidden;">
      <a href="{{img.FileURL}}" download="{{img.Name}}" style=" padding: 0 !important; background: none !important;left: 0;top: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;"> <img style="max-width:100px !important;max-height:100px !important;width:auto;height:auto;" [src]="img.FileURLDisplay" alt="img" /></a>
      <!--<img src={{img.FileURLDisplay}} alt="img" />-->
      <a *ngIf="CanEditFiles" (click)="RemovePTFile(index)"><img src="https://d3cuf9vufwcurr.cloudfront.net/project/admin/image/7618d85f-e810-42aa-92d0-6ef6ccc72fa4-crosswhite.png" alt="img" /></a>
    </div>
  </ng-container>

</div>
