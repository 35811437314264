import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { DefaultTS } from '../SharedComponents/Default';
import { ConversationList } from '../ConversationList/ConversationList';
import { json } from 'express';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
/*import set from 'date-fns/esm/fp/set';*/

@Component({
  selector: 'Conversation',
  templateUrl: './Conversation.html'
})
export class Conversation {
  @ViewChild(ConversationList) child: ConversationList;

  //@ViewChild(MyConversations, { static: false })
  //Count: MyConversations;

  public http: HttpClient;
  public baseUrl: string;
  public AddAttachmentMode = false;
  public InputFields = {} as any;
  public Message = [] as any;
  public Attachment = [] as any;
  public Conversations = [] as any;
  public AvailableHeight = window.innerHeight;

  public MediaUrl = "";
  public IsMp4 = false;

  public PTFilesList = [];
  @Input() public MaxNumberOfFiles = 1000;
  @Input() public UploadType = 1;

  @Input() public OtherEntityID = 0;
  @Input() public MessageTypeID = 0;
  @Input() public AutoSaveMessage = false;
  @Input() public SendWAMessage = false;
  public LoggedInUserID = 0;
  constructor(http: HttpClient, private router: Router, private cd: ChangeDetectorRef, private zone: NgZone, private Default: DefaultTS, public convers: ConversationList, @Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal) {
    this.http = http;
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.CheckAndRefreshDataTimeStamp();
      }, 5000);
    }

    //setInterval(() => {
    //  this.AjaxLoadMessages();
    //}, 60000);
  }
  ngOnInit() {

  }
  ngAfterViewInit() {
    this.AvailableHeight = window.innerHeight;
  }
  onResize(event) {
    this.AvailableHeight = window.innerHeight;
  }

  ngOnChanges() {
    if (this.AutoSaveMessage && (this.InputFields.Content && this.InputFields.Content != '') || (this.PTFilesList.length > 0))
      this.SaveMessage();
    else if (!this.AutoSaveMessage)
      this.AjaxLoadMessages();
  }

  public AjaxLoadMessages() {
    this.PTFilesList = [];
    let PostData = {} as any;
    PostData.OtherEntityID = this.OtherEntityID;
    PostData.MessageTypeID = this.MessageTypeID;

    // this.Default.SetMasterAnimation(true);
    this.http.post('api/Conversation/AjaxLoadMessages', PostData).subscribe(result => {
      var l_arrResponse = result as any;
      //  this.Default.SetMasterAnimation(false);

      this.Message = [];
      this.Attachment = [];

      if (l_arrResponse[0][0].Status == 1) {
        this.LoggedInUserID = l_arrResponse[0][0].LoggedInUserID;
        //alert(this.LoggedInUserID);
        this.Message = l_arrResponse[1];

        this.Attachment = l_arrResponse[2];

        for (var i = 0; i < this.Attachment.length; i++) {
          debugger;
          this.Attachment[i].FileURL = this.Default.GetFileIconToDisplay(this.Attachment[i].FileURL);
        }
        this.UpdateRecordsTimeStamp();
      }
      else {
      }
    }, error => console.error(error));
  }

  public DeleteMessage(p_intMessageID) {
    let data = {} as any;
    data.MessageID = p_intMessageID;
    this.http.post('api/Conversation/DeleteMessage', data).subscribe(result => {
      this.AjaxLoadMessages();
    }, error => console.error(error));
  }

  public GetUnreadMessages() {
    this.http.get('api/Conversation/GetUnreadMessages').subscribe(result => {
      //localStorage.setItem('count', result.toString())

      //localStorage.setItem("count", this.TotalUnreadMessages.toString());
    }, error => console.error(error));
  }
  public SaveMessage() {
    let PostData = {} as any;
    PostData.Content = '';
    PostData.FileURLs = '';
    PostData.OtherEntityID = this.OtherEntityID;
    PostData.MessageTypeID = this.MessageTypeID;
    PostData.SendWAMessage = this.SendWAMessage;
    var l_boolIsOkay = true;
    var l_strMessage = "Following are required fields: ";

    for (let field in PostData) {
      if (this.InputFields[field] !== undefined && this.InputFields[field] != null)
        PostData[field] = this.InputFields[field];
    }

    PostData.FilesData = this.PTFilesList;
    if ((PostData.Content == '' && this.PTFilesList.length == 0) && !this.AutoSaveMessage) {
      l_strMessage += '*Message'
      l_boolIsOkay = false;
    }

    if (l_boolIsOkay) {
      this.PTFilesList = [];
      this.AjaxSaveMessage(PostData);
      this.AjaxLoadMessages();
    }
    else {
      alert(l_strMessage);
      //this.Default.ShowAlert(l_strMessage);
    }
  }

  public AjaxSaveMessage(PostData: any) {
    this.Default.SetMasterAnimation(true);
    this.InputFields.Content = '';
    this.http.post('api/Conversation/AjaxSaveMessage', PostData).subscribe(result => {
      var l_arrResponse = result as any;

      this.Default.SetMasterAnimation(false);
      this.AjaxLoadMessages();
      if (l_arrResponse[0][0].Status == 1) {
        this.LoggedInUserID = l_arrResponse[0][0].LoggedInUserID;
        this.AjaxLoadMessages();
        this.Message = l_arrResponse[1];
        this.Attachment = l_arrResponse[2];
        this.GetUnreadMessages();
        this.convers.LoadConversationList();

        for (var i = 0; i < this.Attachment.length; i++) {
          this.Attachment[i].FileURL = this.Default.GetFileIconToDisplay(this.Attachment[i].FileURL);
        }
      }
      else {
      }
    }, error => console.error(error));
  }

  public ResponseFromPTUploader(data: any) {
    this.PTFilesList = data.PTFilesList;
  }

  public CheckAndRefreshDataTimeStamp() {
    this.UpdateRecordsTimeStamp();

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.CheckAndRefreshDataTimeStamp();
      }, 5000);
    }
  }

  public UpdateRecordsTimeStamp() {
    for (var i = 0; i < this.Message.length; i++) {
      var l_oDate1 = new Date(this.Message[i]['CreatedDate']);
      var l_oDate2 = this.Default.ConvertDateToETFormat(new Date());

      var l_decSeconds = this.Default.GetDifferenceBetweenTwoDatesInSeconds(l_oDate1, l_oDate2);
      var l_decMinutes = Math.round(l_decSeconds / 60);
      var l_decHours = Math.round(l_decMinutes / 60);
      var l_decDays = Math.round(l_decHours / 24);

      if (l_decSeconds < 60)
        this.Message[i]['CreatedDateDisplay'] = '< 1m';
      else if (l_decMinutes < 60)
        this.Message[i]['CreatedDateDisplay'] = l_decMinutes + 'm';
      else if (l_decHours < 24)
        this.Message[i]['CreatedDateDisplay'] = l_decHours + 'h';
      else if (l_decDays < 7)
        this.Message[i]['CreatedDateDisplay'] = l_decDays + 'd';
      else
        this.Message[i]['CreatedDateDisplay'] = l_oDate1.getMonth().toString().padStart(2, '0') + '/' + l_oDate1.getDate().toString().padStart(2, '0') + '/' + l_oDate1.getFullYear();
    }
  }
  message: string = "Hello!";
  @Output() messageEvent = new EventEmitter<string>();
  sendMessage() {
    debugger;
    this.messageEvent.emit(this.message)
  }

  public closeResult;
  public ShowMediaModal(content) {
    if (this.MediaUrl.includes(".mp4")) {
      this.IsMp4 = true;
    } else {
      this.IsMp4 = false;
    }
    
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
