<div class="cs-height__30 cs-height__lg__30"></div>

  <div class="container" style="max-width:550px;margin:0;">
    <div class="cs-form cs-style2">
      <div class="row">
        <!--<h1 style="font-weight:bold;text-align:center;font-size:30px;">Update Password</h1>-->
        <div class="cs-height__40 cs-height__lg__25"></div>
        <div class="col-sm-12">
          <input [ngClass]="ShowErrors[0] == ''? 'cs-form__field':'cs-form__field RedBorderClass'" placeholder="Enter Current Password" [(ngModel)]="ResetPasswordObject.CurrentPassword" type="password" (keyup)="$event.keyCode == 13 ? CheckResetPasswordValidation(0): CheckResetPasswordValidation(1)">
          <div class="cs-height__10 cs-height__lg__10"></div>
        </div>
        <div class="col-sm-12">
          <input [ngClass]="ShowErrors[1] == ''? 'cs-form__field':'cs-form__field RedBorderClass'" placeholder="Enter New Password" [(ngModel)]="ResetPasswordObject.Password" type="password" (keyup)="$event.keyCode == 13 ? CheckResetPasswordValidation(0): CheckResetPasswordValidation(2)">
          <div class="cs-height__10 cs-height__lg__10"></div>
        </div>
        <div class="col-sm-12">
          <input  [ngClass]="ShowErrors[2] == ''? 'cs-form__field':'cs-form__field RedBorderClass'" placeholder="Confirm New password" [(ngModel)]="ResetPasswordObject.ConfirmPassword" type="password" (keyup)="$event.keyCode == 13 ? CheckResetPasswordValidation(0): CheckResetPasswordValidation(3)">
          <span [innerHTML]="ShowErrors[3]" style="color:red;font-size:12px;">{{ShowErrors[3]}}</span>
          <div class="cs-height__10 cs-height__lg__10"></div>
        </div>
        <div class="col-sm-12">
          <a *ngIf="!PageButtonActionStatus.ResetPasswordButton" (click)="CheckResetPasswordValidation(0);" class="cs-btn cs-style1 cs-color1 cs-primary__font w-100">
              Submit
            </a>
          <a *ngIf="PageButtonActionStatus.ResetPasswordButton" class="cs-btn cs-style1 cs-color1 cs-primary__font w-100">
            <div class="cs-preloader__in" style="width:20px;height:20px;padding:0;">
            </div>
            </a>
     
          <div class="cs-height__15 cs-height__lg__25"></div>

        </div>
      </div>
    </div>
  </div>
