import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DefaultTS } from '../SharedComponents/Default';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { QuoteService } from '../Quote/quote.service';
import { json } from 'express';

@Component({
  selector: 'app-QuoteInfo',
  templateUrl: './QuoteInfo.html'
})
export class QuoteInfo {

  @Input() public QuoteID;
  @Input() public MNVQuoteID = 0;

  public JobRelatedData = {} as any;
  public AllLinkedQuotes = [] as any;

  constructor(public datepipe: DatePipe, public http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, public defaultTS: DefaultTS, private router: Router, public service: QuoteService, private route: Router) {
    this.defaultTS.IsPorter = true;
  }

  ngOnInit() {
    this.defaultTS.ScrollToTop();
    if (this.QuoteID > 0 && this.MNVQuoteID == 0)
      this.LoadAllQuotesLinkedToThisJob(this.QuoteID);
  }

  public LoadAllQuotesLinkedToThisJob(MNVJobID) {
    let data = {} as any;
    data.MNVJobID = MNVJobID;
    this.http.post('api/MNVQuote/MNVQuoteGetDataSetByMNVJobIDCustom', data).subscribe(result => {
      if (this.MNVQuoteID == 0) {
        this.AllLinkedQuotes = result[0];
        if (result[1] && result[1][0])
          this.JobRelatedData.Amounts = result[1][0];
      }
    }, error => console.error(error));
  }

}
