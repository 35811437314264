<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8" />
  <title></title>
</head>
<body>
  <a class="btn btn-primary BrowseFile">
    <input #fileInput type="file" />
    Browse
  </a>
  <!--<button (click)="AddFile()" class="btn btn-primary">Upload</button>-->

  <div class="AssessmentTimer">
    <span id="demo"></span>
  </div>
</body>
</html>
