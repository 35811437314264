import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class QuoteService {
  public from;
  public to;
  public fromDetail = {} as any;
  public toDetail = {} as any;
  public AddressApiKey = "-BeLNIcl0EaCtL3ULbdZJA32796";
  public IsNewQuote = false;
  constructor(private http: HttpClient, @Inject(DOCUMENT) private dom) { }

  public AddressAutoComplete(code) {
    return this.http.get('https://api.getAddress.io/autocomplete/' + code + '?expand=true&all=true&api-key=' + this.AddressApiKey + '')
  }

  public GetAddressById(id) {
    return this.http.get('https://api.getAddress.io/get/' + id + '?expand=true&api-key=' + this.AddressApiKey + '')
  }

  public setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }


}
