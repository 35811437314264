import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SortJsonData',
  pure: false
})
export class SortJsonData implements PipeTransform {
  //transform(array: Currency[], args: string): Currency[] {
  //    array.sort((a: any, b: any) => {
  //        if (a[args] < b[args]) {
  //            return -1;
  //        } else if (a[args] > b[args]) {
  //            return 1;
  //        } else {
  //            return 0;
  //        }
  //    });
  //    return array;
  //}
  value: string[] = [];

  static _orderByComparator(a: any, b: any): number {
    if (a === null || typeof a === 'undefined') a = 0;
    if (b === null || typeof b === 'undefined') b = 0;

    if (typeof a == 'string' || typeof b == 'string') {
      //Isn't a number so lowercase the string to properly compare
      a = a.toString();
      b = b.toString();
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    }
    else if (typeof a == 'number' || typeof b == 'number') {
      //Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }
    else if (typeof a == 'object' || typeof b == 'object') {
      //Parse strings as numbers to compare properly
      if (new Date(a).getTime() < new Date(b).getTime()) return -1;
      if (new Date(a).getTime() > new Date(b).getTime()) return 1;
    }
    else {
      a = a.toString();
      b = b.toString();
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    }

    return 0; //equal each other
  }

  transform(input: any, config: string = '+'): any {
    //make a copy of the input's reference
    if (input !== null && input !== undefined) {
      this.value = [...input];
      var value = this.value;

      if (!Array.isArray(value)) return value;

      if (!Array.isArray(config) || (Array.isArray(config) && config.length == 1)) {
        var propertyToCheck: string = !Array.isArray(config) ? config : config[0];
        var desc = propertyToCheck.substr(0, 1) == '-';

        //Basic array
        if (!propertyToCheck || propertyToCheck == '-' || propertyToCheck == '+') {
          return !desc ? value.sort() : value.sort().reverse();
        }
        else {
          var property: string = propertyToCheck.substr(0, 1) == '+' || propertyToCheck.substr(0, 1) == '-'
            ? propertyToCheck.substr(1)
            : propertyToCheck;

          return value.sort(function (a: any, b: any) {
            return !desc
              ? SortJsonData._orderByComparator(a[property], b[property])
              : -SortJsonData._orderByComparator(a[property], b[property]);
          });
        }
      }
      else {
        //Loop over property of the array in order and sort
        return value.sort(function (a: any, b: any) {
          for (var i: number = 0; i < config.length; i++) {
            var desc = config[i].substr(0, 1) == '-';
            var property = config[i].substr(0, 1) == '+' || config[i].substr(0, 1) == '-'
              ? config[i].substr(1)
              : config[i];

            var comparison = !desc
              ? SortJsonData._orderByComparator(a[property], b[property])
              : -SortJsonData._orderByComparator(a[property], b[property]);

            //Don't return 0 yet in case of needing to sort by next property
            if (comparison != 0) return comparison;
          }

          return 0; //equal each other
        });
      }
    }
  }
}
