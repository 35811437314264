import { Component } from '@angular/core';

@Component({
  selector: 'Timer',
  templateUrl: './Timer.html'
})
export class Timer {
  private Hours = 0;
  public Minutes = 0;
  public Seconds = 0;
  public Distance = 0;
  public x: any;
  constructor() {
  }

  //public StartTimerNow() {
  //  var l_strAddress = window.location.href;
  //  l_strAddress = l_strAddress.toLocaleLowerCase();
  //  if (l_strAddress.indexOf('assessment') > -1) {
  //    var now2 = new Date();
  //    // Update the count down every 1 second
  //    this.x = setInterval(function () {
  //      // Get todays date and time
  //      var now = new Date();

  //      if (!(this.boolThisValue)) {
  //        this.boolThisValue = true;
  //      }
  //      // Find the distance between now an the count down date
  //      var distance = now.getTime() - now2.getTime();

  //      // Time calculations for days, hours, minutes and seconds
  //      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //      this.Hours = hours;
  //      this.Minutes = minutes;
  //      this.Seconds = seconds;

  //      // Output the result in an element with id="demo"
  //      if (hours > 0)
  //        document.getElementById("demo")!.innerHTML = hours + "h " + minutes + "m " + seconds + "s ";
  //      else if (minutes > 0)
  //        document.getElementById("demo")!.innerHTML = minutes + "m " + seconds + "s ";
  //      else if (seconds > 0)
  //        document.getElementById("demo")!.innerHTML = seconds + "s ";

  //      document.getElementById('TimeSpent')!.innerHTML = ((hours * 60) + minutes + Math.ceil(seconds / 60)) + '';
  //      // If the count down is over, write some text
  //      if (distance < 0) {
  //        clearInterval(this.x);
  //        document.getElementById("demo")!.innerHTML = "EXPIRED";
  //      }
  //    }, 1000);
  //  }
  //}
  public ReturnTimeSpent() {
    var l_intMinutes = document.getElementById('TimeSpent')!.innerHTML;
    return l_intMinutes;
  }
  public ResetTimer() {
    clearTimeout(this.x);
    document.getElementById('demo')!.innerHTML = '';
  }
}
