import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, Injectable, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DefaultTS } from '../SharedComponents/Default';

@Component({
  selector: 'app-UpdatePassword',
  templateUrl: './UpdatePassword.html'
})
export class UpdatePassword {
  public http: HttpClient;
  public ResetPasswordObject: any = {} as any;
  public ShowErrors = [] as any;
  public PageButtonActionStatus: any = { ResetPasswordButton: false } as any;
  constructor(http: HttpClient, private router: Router, public Default: DefaultTS, private cd: ChangeDetectorRef, private zone: NgZone, private router2: ActivatedRoute) {
    this.http = http;
    this.ShowErrors[0] = '';
    this.ShowErrors[1] = '';
    this.ShowErrors[2] = '';
    this.ShowErrors[3] = '';
    this.ResetPasswordObject.CurrentPassword = '';
    this.ResetPasswordObject.Password = '';
    this.ResetPasswordObject.ConfirmPassword = '';


  
  }
 
  public CheckResetPasswordValidation(Type: number): any {
    var l_boolOkyToGo = true;
    this.ShowErrors[3] = '';
    if (Type == 0 || Type == 1) {
      this.ShowErrors[0] = '';
      if (this.ResetPasswordObject.CurrentPassword == '') {
        this.ShowErrors[0] = 'Current password required.';
        this.ShowErrors[3] = 'Current password required.';
        l_boolOkyToGo = false;
      }
    }
    if (Type == 0 || Type == 2) {
      this.ShowErrors[1] = '';
      if (this.ResetPasswordObject.Password == '') {
        this.ShowErrors[1] = 'New password required.';
        this.ShowErrors[3] = 'New password required.';
        l_boolOkyToGo = false;
      }
      else {
        var DataPassword = this.Default.PTCheckPasswordValidation(this.ResetPasswordObject.Password, '');
        if (!DataPassword.Valid) {
          l_boolOkyToGo = false;
          this.ShowErrors[1] = DataPassword.Message;
          this.ShowErrors[3] = DataPassword.Message;
        }
      }
    }
    if (Type == 0 || Type == 3) {
      this.ShowErrors[2] = '';
      if (this.ResetPasswordObject.ConfirmPassword == '') {
        this.ShowErrors[2] = 'Confirm password required.';
        this.ShowErrors[3] = 'Confirm password required.';
        l_boolOkyToGo = false;
      }
    }
    if (l_boolOkyToGo && this.ResetPasswordObject.CurrentPassword !== '' &&  this.ResetPasswordObject.Password !== '' && this.ResetPasswordObject.ConfirmPassword !== '') {
      if (this.ResetPasswordObject.Password !== this.ResetPasswordObject.ConfirmPassword) {
        this.ShowErrors[2] = 'Password and confirm password do not match.';
        this.ShowErrors[3] = 'Password and confirm password do not match.';
        l_boolOkyToGo = false;
      }
    }




    if (l_boolOkyToGo && Type == 0)
      this.UpdatePassword();


  }

  public UpdatePassword() {
    let data = {} as any;
    data.CurrentPassword = this.ResetPasswordObject.CurrentPassword;
    data.Password = this.ResetPasswordObject.Password;
    data.ConfirmPassword = this.ResetPasswordObject.ConfirmPassword;
    //this.Default.SetMasterAnimation(true);
    this.PageButtonActionStatus.ResetPasswordButton = true;
    this.http.post('api/SiteUser/AjaxChangeUserPassword', data).subscribe(result => {
      //this.Default.SetMasterAnimation(false);
      this.PageButtonActionStatus.ResetPasswordButton = false;
      var l_ArrResponse = result as any;
      if (l_ArrResponse[0][0].Status == 1) {
        this.Default.ShowMessage(1, l_ArrResponse[0][0].Message);
        setTimeout(() => {
          this.router.navigate(['home']);
        }, 3000);
      }
      else {
        this.Default.ShowMessage(2, l_ArrResponse[0][0].Message);
      }
    }, error => console.error(error));
  }


}
