<div class="cs-height__30 cs-height__lg__30"></div>
<div class="two-factor">
  <div class="container" style="max-width:550px; margin:0;">
    <div class="cs-form cs-style2">
      <div class="row">
        <!--<h1 style="font-weight:bold;text-align:center;font-size:30px;">Update Mobile</h1>-->
        <span *ngIf="AccountProfile.EnterPassCode" style="font-size: 14px;line-height: 20px;">
          Please enter 6 digit passcode that we sent on your mobile number.
        </span>
        <div class="cs-height__15 cs-height__lg__15"></div>
        <div class="col-sm-12" style="position:relative;" *ngIf="!AccountProfile.EnterPassCode">
          <img *ngIf="AccountProfile.MobileVerified" src="assets/img/Icon-check.svg" title="Verified" class="verified--check" width="20" height="20" />
          <img *ngIf="!AccountProfile.MobileVerified" src="assets/img/Icon-cross.svg" title="Not Verified" class="verified--check" width="15" height="15" />
          <span>Enter Mobile</span>
          <div class="cs-height__5 cs-height__lg__5"></div>
          <app-PTMobileInput [RequiredInputMessage]="'Please enter mobile number'" [SelectedMobileNumber]="SelectedMobileNumber" [IsMobileNumberRequired]="true" (ResponseFromMobileInput)="ResponseFromMobileInput($event)" ></app-PTMobileInput>
      
          <div class="cs-height__10 cs-height__lg__10"></div>
        </div>
        <div class="col-sm-12" *ngIf="AccountProfile.EnterPassCode">
          <input [ngClass]="ShowErrors[20] == ''? 'cs-form__field':'cs-form__field RedBorderClass'" placeholder="Enter Passcode" [(ngModel)]="AccountProfile.Passcode" type="text" (keyup)="$event.keyCode == 13 ? VerifyPassCode(1): VerifyPassCode(0)">
          <span style="font-size: 12px;line-height: 20px;color:red;">{{ShowErrors[20]}}</span>
          <div class="cs-height__10 cs-height__lg__10"></div>
        </div>

        <div class="col-sm-12">
          <a *ngIf="!AccountProfile.EnterPassCode" (click)="SendPassCode(1);" class="cs-btn cs-style1 cs-color1 cs-primary__font w-100">
            Submit
          </a>
          <a *ngIf="AccountProfile.EnterPassCode" (click)="VerifyPassCode(1);" class="cs-btn cs-style1 cs-color1 cs-primary__font w-100">
            Verify
          </a>

          <div *ngIf="AccountProfile.EnterPassCode">
            <div class="cs-height__10 cs-height__lg__10"></div>
            <div class="signin-reset noaccount st-auth-10" style="font-size: 14px; line-height: 20px;">
              <span>Didn't get the code? </span> <a (click)="SendPassCode(1)" style="color: #482064;">Resend</a>
            </div>
            <div class="cs-height__10 cs-height__lg__10"></div>
            <div class="signin-reset noaccount st-auth-11" style="font-size: 14px; line-height: 20px;">
              <span>Want to use a different number?</span> <a (click)="AccountProfile.EnterPassCode=false;" style="color: #482064;">Change Phone Number</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" style="max-width:550px; margin:0;" *ngIf="AccountProfile.CanAvailTwoFactorAuth && ShowTwoFactor">
    <div class="cs-form cs-style2">
      <div class="row">
        <div class="cs-height__15 cs-height__lg__15"></div>
        <span>Two Factor Authentication</span>
        <div class="cs-height__5 cs-height__lg__5"></div>
        <span *ngIf="!AccountProfile.MobileVerified" style="font-size: 14px;line-height: 20px;">To enable two factor authentication, please verify your mobile number first</span>
        <div class="col-sm-12">
          <div class="Enable-Disable">
            <span>Disabled</span>
            <div [ngClass]="!AccountProfile.MobileVerified ? 'yesnocheck yn-check':'yesnocheck'">
              <input type="checkbox" [(ngModel)]="AccountProfile.TwoFactorAuth" (change)="SaveTwoFactorAuth()" [disabled]="!AccountProfile.MobileVerified" />
              <img src="assets/img/On.svg" *ngIf="AccountProfile.TwoFactorAuth" width="50" height="31" title="Enabled" />
              <img src="assets/img/Off.svg" *ngIf="!AccountProfile.TwoFactorAuth" width="50" height="31" title="Disabled" />
            </div>
            <span>Enabled</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
