import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
//import { } from '@types/googlemaps';

@Component({
  selector: 'AutocompleteComponent',
  template: `
      <input class="input"
        type="text"
        [(ngModel)]="autocompleteInput"
        #addresstext
        >
    `,
})
export class AutocompleteComponent implements OnChanges, AfterViewInit {
  @Input() adressType: string;
  @Input() country: string = "FR";
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  @Input() autocompleteInput: string = "";
  queryWait: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  ngOnChanges() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    if (this.addresstext != undefined) {
      google.maps.event.clearInstanceListeners(this.addresstext.nativeElement);
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
          componentRestrictions: { country: this.country },
          fields: ["address_components"],
          types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.invokeEvent(place);
      });
    }
  }

  invokeEvent(place: any) {
    if (this.addresstext != null && this.addresstext.nativeElement != null) {
      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "postal_code": {
            this.addresstext.nativeElement.value = component.short_name;
            break;
          }          
        }
      }
    }

    this.setAddress.emit(place);
  }

}
