import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DefaultTS } from '../SharedComponents/Default';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { QuoteService } from '../Quote/quote.service';
import { json } from 'express';

@Component({
  selector: 'QuoteHeader',
  templateUrl: './QuoteHeader.html'
})
export class QuoteHeader {
  data = [];
  keyword = 'address';
  public ShowQuote = false;
  public FromPostalCode: string;
  public ToPostalCode: string;
  @Input() public Type = 1; /*1=ManVan,2=Removals*/

  public Error: any = {} as any;

  constructor(public datepipe: DatePipe, public http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, private defaultTS: DefaultTS, private router: Router, public service: QuoteService, private route: Router) {
    this.SetErrorDefaultValues();
  }

  public SetErrorDefaultValues() {

    this.Error.IsFromFill = true;
    this.Error.IsToFill = true;

  }

  getAddress(code) {
    this.service.AddressAutoComplete(code).subscribe(result => {
      this.data = result['suggestions'];
    }, error => {
      console.log(error);
    });
  }
  getAddressBreakup(key, number) {
  

    this.service.GetAddressById(key).subscribe(result => {
      if (number == 1) {
        this.service.fromDetail = result;
      } else {
        this.service.toDetail = result;
      }
    }, error => {
      console.log(error);
    });
  }
  GoToQuote() {

    let IsOK = true;
    this.Error.IsFromFill = true;
    this.Error.IsToFill = true;

    if (this.Type == 2) {

      if (this.from == "") {
        this.defaultTS.ShowMessage(2, "From postcode is required");
        IsOK = false;
        this.Error.IsFromFill = false;
      }
      if (this.service.from == undefined) {
        this.defaultTS.ShowMessage(2, "Please select From postcode address");
        IsOK = false;
        this.Error.IsFromFill = false;
      }
       if (this.to == "") {
         this.defaultTS.ShowMessage(2, "To postcode is required");
         IsOK = false;
         this.Error.IsToFill = false;
      }
      if (this.service.to == undefined) {
        this.defaultTS.ShowMessage(2, "Please select To postcode address");
        IsOK = false;
        this.Error.IsToFill = false;
      }

      if (IsOK == true) {
     /*   (<HTMLInputElement>document.getElementById('loader')).style.display = 'flex';*/
        this.route.navigate(['/removals', this.service.fromDetail.postcode, this.service.toDetail.postcode]);
      }

    }
    else {
 
      if (this.from == "") {
        this.defaultTS.ShowMessage(2, "From postcode is required");
        IsOK = false;
        this.Error.IsFromFill = false;
      }
      if (IsOK && this.service.from == undefined) {
        this.defaultTS.ShowMessage(2, "Please select From postcode address");
        IsOK = false;
        this.Error.IsFromFill = false;
      }
      if (this.to == "") {
        this.defaultTS.ShowMessage(2, "To postcode is required");
        IsOK = false;
        this.Error.IsToFill = false;
      }
      if (IsOK && this.service.to == undefined) {
        this.defaultTS.ShowMessage(2, "Please select To postcode address");
        IsOK = false;
        this.Error.IsToFill = false;
      }

      this.defaultTS.FromAndToSelection.fromDetail = this.service.fromDetail;
      this.defaultTS.FromAndToSelection.toDetail = this.service.toDetail;

      if (IsOK == true) {
     /*   (<HTMLInputElement>document.getElementById('loader')).style.display = 'flex';*/
        this.route.navigate(['/quotes']);
      }
    }
  }
  onFromSelected(item) {
    this.data = [];
    this.getAddressBreakup(item.id, 1);
    this.service.from = item.address;
  }
  public fromSelected;
  public toSelected;

  onToSelected(item) {
    this.data = [];
    this.getAddressBreakup(item.id, 2);
    this.service.to = item.address;
   
  }
  public from = [] as any;
  public to = [] as any;

  onFromChangeSearch(val: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('myQuery');
      localStorage.removeItem('myQuote');
    }
    this.service.AddressAutoComplete(val).subscribe(res => {
      this.from = res['suggestions'];
      if (this.from != null) {
        for (var i = 0; i < this.from.length; i++) {
          this.from[i].address += ',' + ' ' + val;
        }
      }
    })
  }
  onToChangeSearch(val: string) {
  
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('myQuery');
      localStorage.removeItem('myQuote');
    }
    this.service.AddressAutoComplete(val).subscribe(res => {
      this.to = res['suggestions'];
      if (this.to != null) {
        for (var i = 0; i < this.to.length; i++) {
          this.to[i].address += ',' + ' ' + val;
        }
      }
    })
  }
}
