import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { SharedDataModule } from './SharedData.module';
import { ToastrModule } from 'ngx-toastr'
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RatingModule } from 'ngx-bootstrap/rating';
import { JsonLdModule } from 'ngx-seo';
import { Login } from './Login/Login';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    Login,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedDataModule.forRoot(),
    AppRoutingModule,
    RatingModule,
    JsonLdModule,
    //ChartsModule,
    RouterModule.forRoot([
      { path: 'login', component: Login, pathMatch: 'full' },
    //{ path: 'dashboard', component: DashboardComponent, pathMatch: 'full' },
      { path: '', component: Login, pathMatch: 'full' },
      { path: '**', component: Login, pathMatch: 'full' },
], { relativeLinkResolution: 'legacy' }),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-right',
        preventDuplicates: true
      }),
  ],
  providers: [
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: TokenInterceptorService,
    //  multi: true,
    //},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
