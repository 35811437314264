<div class="multi-select" #componentRef>
  <a class="multi-ps" (click)="showOptions = !showOptions">
    <span>{{ selectedName }}</span>
    <img src="/assets/img/drop-chev.svg" width="13" height="8" alt="Chev down" />
  </a>
  <div class="multi-drop" *ngIf="showOptions">
    <div class="multi-list">
      <div class="multi-item">
        <input type="checkbox" [checked]="selectUnSelectAll" (change)="selectUnselectAll($event)" />
        <span>Select/Unselect All</span>
      </div>
      <div class="multi-item" *ngFor="let item of currentList; let i = index">
        <input type="checkbox" [checked]="item.IsSelected" (change)="updateListdata(i)" />
        <span>{{ item.Name }}</span>
      </div>
    </div>
    <a class="btn-primary" (click)="sendResponseToParent()">Filter</a>
  </div>
</div>
