import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DefaultTS } from '../SharedComponents/Default';

// Public Page: Accessible to every visitor and crawlers

@Component({
  selector: 'app-Login',
  templateUrl: './Login.html',
  styleUrls: ['./Login.scss']
})
export class Login {
  public UserName: string;
  public Password: string;
  public userEmail: string;
  public data: any = {} as any;
  public http: HttpClient;
  public l_arrResponse = [] as any;
  public ShowErrors = [] as any;
  public CallLogin = false; 
  public AddVerificationCode = false; 
  public Passcode = '';
  constructor(http: HttpClient, private router: Router, public Default: DefaultTS) {
    this.http = http;
    this.UserName = "";
    this.Password = "";
    this.userEmail = "";
    this.Default.IsPorter = false;
    this.Default.SetMetaData(149); 
    this.AjaxLoginCheckLoginData();  
/*    this.Default.AddUserTracking('login','',2);*/
  }
  public AjaxLoginCheckLoginData() {
    let data = {} as any;
    this.http.post('api/SiteUser/AjaxLoginCheckLoginData', data).subscribe(result => {
      var Result = [] as any;
      Result = result;
      var StatusData = Result[0][0] as any;
      this.Default.IsUserLoggedIn = false;
      if (StatusData.Status == 1) {

        var URL = 'dashboard';
        this.Default.UserRole = StatusData.Role;
        this.Default.UserRoleActual = StatusData.RoleActual;
        this.Default.IsSemiAdminLoggedIn = false;
        if (StatusData.Role == "Admin") {
          URL = 'dashboard';
          this.Default.IsAdminLoggedIn = true;
          if (StatusData.RoleActual == "SemiAdmin")
            this.Default.IsSemiAdminLoggedIn = true;
        }
        else if (StatusData.Role == "Driver" || StatusData.Role == "Helper")
          URL = "driverdashboard";
        
        this.Default.Role.push(StatusData.Role);

        this.Default.IsUserLoggedIn = true;
        this.Default.UserName = StatusData.UserName;
        this.Default.ProfileImageURL = StatusData.ImageURL;
        this.Default.UserInitials = StatusData.UserInitials;
        this.Default.UserInitialsSignature = StatusData.UserInitialsSignature;
        this.Default.CompaniesCount = StatusData.CompaniesCount;
        this.Default.IsEditor = StatusData.IsEditor;
        this.Default.ManageCompanies = StatusData.ManageCompanies;
        this.Default.ManageAdmin = StatusData.ManageAdmin;
        this.Default.SelectedCompanyID = StatusData.SelectedCompanyID;
        this.Default.SelectedCompanyName = StatusData.SelectedCompanyName;
        this.Default.SelectedCompanyLogo = StatusData.SelectedCompanyLogo;
        window.location.href = URL;
      }
    }, error => console.error(error));
  }









  public VerifyUser() {


    let data = {} as any;
    this.Default.IsUserLoggedIn = false;
    data.UserName = this.UserName;
    data.Password = this.Password;
    this.http.post('api/SiteUser/GetUserLogin', data).subscribe(result => {
      var Result = [] as any;
      Result = result;
      var StatusData = Result[0] as any;
      this.Default.IsUserLoggedIn = false;
      if (StatusData) {
        if (StatusData[0].Status == 1) {
          this.Default.IsUserLoggedIn = true;
          this.Default.UserName = StatusData[0].UserName;
          this.Default.UserInitials = StatusData[0].UserInitials;
          this.Default.UserInitialsSignature = StatusData[0].UserInitialsSignature;
          this.Default.UserRole = StatusData.Role;
          this.Default.UserRoleActual = StatusData.RoleActual;
          this.Default.IsSemiAdminLoggedIn = false;
          var FinalPage = "dashboard";
          if (StatusData[0].Role == "Admin") {
            this.Default.IsAdminLoggedIn = true;
            if (StatusData[0].RoleActual == "SemiAdmin")
              this.Default.IsSemiAdminLoggedIn = true;
          }
          else if (StatusData[0].Role == "Driver") {
            this.Default.IsDriverLoggedIn = true;
            FinalPage = "driverdashboard";
          }
          else if (StatusData[0].Role == "Helper") {
            this.Default.IsHelperLoggedIn = true;
            FinalPage = "driverdashboard";
          }
          else {
            FinalPage = "https://www.finestvan.co.uk";
          }

          this.Default.Role.push(StatusData[0].Role);
          var ClientData = Result[1] as any;
          if (ClientData && ClientData.length > 0) {
            this.Default.ClientInfo = ClientData[0];
          }

          var MoverData = Result[2] as any;
          if (MoverData[0].IsContractor)
            this.Default.IsDriverAsContractorLoggedIn = true;


          window.location.href = FinalPage;
        }
        else if (StatusData[0].Status == 500) { 
          // 2 factor auth
          this.AddVerificationCode = true;
          this.Default.ShowMessage(1, StatusData[0].Message);
        }
        else if (StatusData[0].Status == 0) {
          this.Default.ShowMessage(2, StatusData[0].Message);
        }
      }

    }, error => console.error(error));
  }

  public LoginValidation(p_SaveClicked: boolean) {
    var l_boolShouldSave = true;
    if (this.CallLogin) {
      this.ShowErrors = [];
      if ((this.UserName == '') || !this.UserName) {
        this.ShowErrors[0] = 'User name required.';
        l_boolShouldSave = false;
      }

      if (this.Password == '' || !this.Password) {
        this.ShowErrors[1] = 'Password required.';
        l_boolShouldSave = false;
      }

      if (l_boolShouldSave && p_SaveClicked)
        this.VerifyUser();
    }
  }

  public SendMessage(TypeID: Number) {
    let data = {} as any;
    data.TypeID = TypeID;
    this.http.post('api/SiteUser/SendTestMessage', data).subscribe(result => {
      //  document.getElementById('litMessage').innerText = '';
      var Result = [] as any;
      Result = result;
      var StatusData = Result[0][0] as any;
      if (StatusData.Status == 1) {
        this.Default.IsUserLoggedIn = true;
        var URL = 'home';
        if (StatusData.Role == "Admin") {
          URL = 'home';
          this.Default.IsAdminLoggedIn = true;
        }
        else if (StatusData.Role == "ManAndVanStaff") {
          URL = 'home';
          this.Default.IsAdminLoggedIn = true;
        }
        else {
          URL = 'home';
        }

        window.location.href = URL;
      }
      else if (StatusData.Status == 0) {
        this.Default.ShowNotification(3, true, StatusData.Message);
        //alert(l_arrResponse[0].Message);
      }
    }, error => console.error(error));
  }

  public RememberMe(event) {
    if (event.target.checked) {

    } else {

    }

  }

  public CheckPasscodeAndLogin(DoIt) {

    if (this.UserName != '' && this.Password != '' && this.Passcode.length == 6) {
      if (DoIt == 1) {
        this.GetUserLoginWithTwoFactorAuth();
      }
    }
    else {
      if (DoIt == 1) {
        this.Default.ShowMessage(2, 'Please valid 6 digit passcode');
      }
    }
  }

  public GetUserLoginWithTwoFactorAuth() {
    let data = {} as any;
    this.Default.IsUserLoggedIn = false;
    data.UserName = this.UserName;
    data.Password = this.Password;
    data.Passcode = this.Passcode;
    this.Default.SetMasterAnimationV2(true);
    this.http.post('api/SiteUser/GetUserLoginWithTwoFactorAuth', data).subscribe(result => {
      this.Default.SetMasterAnimationV2(false);
      var Result = [] as any;
      Result = result;
      var StatusData = Result[0] as any;
      this.Default.IsUserLoggedIn = false;
      if (StatusData) {
        if (StatusData[0].Status == 1) {
          this.Default.IsUserLoggedIn = true;
          this.Default.UserName = StatusData[0].UserName;
          this.Default.UserInitials = StatusData[0].UserInitials;
          this.Default.UserInitialsSignature = StatusData[0].UserInitialsSignature;
          this.Default.UserRole = StatusData.Role;
          this.Default.UserRoleActual = StatusData.RoleActual;
          this.Default.IsSemiAdminLoggedIn = false;
          if (StatusData[0].Role == "Admin") {
            this.Default.IsAdminLoggedIn = true;
            if (StatusData[0].RoleActual == "SemiAdmin")
              this.Default.IsSemiAdminLoggedIn = true;

          }
          else if (StatusData[0].Role == "Driver")
            this.Default.IsDriverLoggedIn = true;
          else if (StatusData[0].Role == "Helper")
            this.Default.IsHelperLoggedIn = true;

          this.Default.Role.push(StatusData[0].Role);

          var ClientData = Result[1] as any;
          if (ClientData && ClientData.length > 0) {
            this.Default.ClientInfo = ClientData[0];
          }
          var MoverData = Result[2] as any;
          if (MoverData[0].IsContractor)
            this.Default.IsDriverAsContractorLoggedIn = true;
          window.location.href = 'home';
        }
        else if (StatusData[0].Status == 0) {
          this.Default.ShowMessage(2, StatusData[0].Message);
        }
      }
    }, error => console.error(error));
  }
  public SendSMS() {
    let data = {} as any;
    this.http.post('api/SiteUser/SendSMSTest', data).subscribe(result => {

    }, error => console.error(error));
  }




  public AddGoogleSheet() {
    this.http.get('api/Configurations/AddGoogleSheet').subscribe(result => {

    }, error => console.error(error));
  }

  public UpdateGoogleSheet() {
    this.http.get('api/Configurations/UpdateGoogleSheet').subscribe(result => {

    }, error => console.error(error));
  }



  public DeleteRow() {
    this.http.get('api/Configurations/DeleteRow').subscribe(result => {

    }, error => console.error(error));
  }

}
