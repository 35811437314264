import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DefaultTS } from '../SharedComponents/Default';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { QuoteService } from '../Quote/quote.service';
import { json } from 'express';

@Component({
  selector: 'app-ServiceInfo',
  templateUrl: './ServiceInfo.html'
})
export class ServiceInfo {
  data = [];
  keyword = 'address';
  public ShowQuote = false;
  public FromPostalCode: string;
  public ToPostalCode: string;
  @Input() public QuoteID = 0;
  @Input() public TotalServices = 1;
  @Input() public ServiceNumber = 1;
  @Input() public IsVanLevel = false;
  public QuoteInfo = {} as any;
  public MNVQuoteAdditionalStops = [] as any;
  public ServiceQuestionAnswers = [] as any;
  public AdditionalRateAndVAT = 0;
  public Floors = ["Ground Floor", "1st Floor", "2nd  Floor", "3rd  Floor", "4th  Floor", "5th  Floor", "6th  Floor", "7th Floor", "8th Floor", "9th Floor", "10th Floor", "11th Floor", "12th Floor", "13th Floor", "14th Floor", "15th Floor", "16th Floor", "17th Floor", "18th Floor", "19th Floor", "20th Floor", "20th Floor+"];

  public AdditionalServices = [] as any;
  public MediaObjectID = 0;

  constructor(public datepipe: DatePipe, public http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, public defaultTS: DefaultTS, private router: Router, public service: QuoteService, private route: Router) {

    this.defaultTS.IsPorter = true;
    this.MediaObjectID = this.QuoteID;
  }

  ngOnInit() {
    this.defaultTS.ScrollToTop();
    if (this.QuoteID > 0) {
      this.GetQuoteInfoById();
      if (!this.defaultTS.IsDriverLoggedIn && !this.defaultTS.IsHelperLoggedIn)
        this.GetInvoiceDataByQuoteID();
    }
  }

  public GetQuoteInfoById() {
    this.http.post('api/MNVQuote/MNVQuoteGetQuoteByID', this.QuoteID).subscribe(result => {

      this.QuoteInfo = result[0][0];
      if (this.QuoteInfo.ParentID > 0)
        this.MediaObjectID = this.QuoteInfo.ParentID;
      else
        this.MediaObjectID = this.QuoteID;

      this.MNVQuoteAdditionalStops = result[1];
      if (!this.IsVanLevel)
        this.AdditionalServices = result[4];
      this.GetServiceQuestionAndAnswers(this.QuoteID);
      this.CalculateAdditionalServicesAmounts();
    }, error => console.error(error));
  }

  public CalculateAdditionalServicesAmounts() {
    var l_intLutonVans = 0;
    var l_intTransitVans = 0;

    var l_intNumberOfMovers = this.QuoteInfo.MNVMoverTypeID;

    if (this.QuoteInfo.MNVVanTypeID == 1)
      l_intTransitVans = 1;
    else
      l_intLutonVans = 1;

    for (var i = 0; i < this.AdditionalServices.length; i++) {
      if (this.AdditionalServices[i].MNVVanTypeID == 1)
        l_intTransitVans += 1;
      else
        l_intLutonVans += 1;

      l_intNumberOfMovers += this.AdditionalServices[i].MNVMoverTypeID;
    }

    if (l_intLutonVans > 0 && l_intTransitVans > 0)
      this.QuoteInfo.MultiVanText = this.defaultTS.NumbersInWords[l_intLutonVans] + " Luton & " + this.defaultTS.NumbersInWords[l_intTransitVans] + " Transit";
    else if (l_intLutonVans > 0)
      this.QuoteInfo.MultiVanText = this.defaultTS.NumbersInWords[l_intLutonVans] + " Luton";
    else if (l_intTransitVans > 0)
      this.QuoteInfo.MultiVanText = this.defaultTS.NumbersInWords[l_intTransitVans] + " Transit";

    this.QuoteInfo.MultiMoverText = this.defaultTS.NumbersInWords[l_intNumberOfMovers] + " Movers";
  }

  public GetServiceQuestionAndAnswers(quoteId) {
    let data = {} as any;
    data.QuoteID = quoteId;
    this.http.post('api/MNVQuote/MNVGetServiceOptionsResponseV1', data).subscribe(result => {
      this.ServiceQuestionAnswers = result[0];
      if (!this.defaultTS.IsDriverLoggedIn && !this.defaultTS.IsHelperLoggedIn)
        this.GetAdditionalCharges();
    }, error => console.error(error));
  }

  public GetAdditionalCharges() {
    let data = {} as any;
    data.MNVQuoteID = this.QuoteID;
    this.http.post('api/MNVRates/MNVRatesGetDataSetForAdditionalCharges', data).subscribe(result => {
      let Rates = result[0];
      let Tier = "Tier" + this.QuoteInfo.TierID + "Add";
      let VanTierRate = Rates.filter(z => z.ObjectTypeID == 1 && z.ObjectID == this.QuoteInfo.MNVVanTypeID)[0];
      let DriverTierRate = Rates.filter(z => z.ObjectTypeID == 2 && z.ObjectID == 0)[0];
      let VanAdditionalRate = VanTierRate[Tier];
      let DriverAdditionalRate = DriverTierRate[Tier];

      if (this.QuoteInfo.MNVMoverTypeID == 1) {
        this.AdditionalRateAndVAT = VanAdditionalRate;
      }
      if (this.QuoteInfo.MNVMoverTypeID == 2) {
        this.AdditionalRateAndVAT = VanAdditionalRate + DriverAdditionalRate
      }
      if (this.QuoteInfo.MNVMoverTypeID == 3) {
        this.AdditionalRateAndVAT = VanAdditionalRate + DriverAdditionalRate + DriverAdditionalRate;
      }
    });
  }

  public LineItems = [] as any;
  public LineMainItems = [] as any;
  public LineItemsAmounts = {
    Credit: 0, Debit: 0, Total: 0, Paid: 0, Refund: 0, Balance: 0, Liability: 0, LiabilityID: 0,
    PendingLineItemCount: 0, OtherDiscount: 0, OverAllOtherDiscount: 0, Cancellation: 0, OverallCancellation: 0, OverAllCredit: 0, OverAllDebit: 0,
    OverAllRefund: 0, OverAllSubtotal: 0, OverAllVAT: 0, OverAllTotal: 0, OverAllPaid: 0, OverAllBalance: 0
  };

  public GetInvoiceDataByQuoteID() {
    let data = {} as any;
    data.MNVQuoteID = this.QuoteInfo.MNVJobID;
    this.http.post('api/MNVQuote/GetInvoiceDataByQuoteID', data).subscribe(result => {

      var l_arrResponseInvoice = result[0] as any;
      var l_arrResponseLineItems = result[1] as any;
      this.LineMainItems = result[2] as any;

      var l_arrLineItems = [] as any;

      this.LineItemsAmounts.Credit = 0;
      this.LineItemsAmounts.Debit = 0;
      this.LineItemsAmounts.Refund = 0;
      this.LineItemsAmounts.Cancellation = 0;
      this.LineItemsAmounts.OverallCancellation = 0;

      this.LineItemsAmounts.OverAllCredit = 0;
      this.LineItemsAmounts.OverAllDebit = 0;
      this.LineItemsAmounts.OverAllRefund = 0;
      this.LineItemsAmounts.OverAllSubtotal = 0;
      this.LineItemsAmounts.OverAllVAT = 0;
      this.LineItemsAmounts.OverAllTotal = 0;
      this.LineItemsAmounts.OverAllPaid = 0;
      this.LineItemsAmounts.OverAllBalance = 0;
      this.LineItemsAmounts.OverAllOtherDiscount = 0;

      if (l_arrResponseInvoice.length > 0)
        this.LineItemsAmounts.Balance = l_arrResponseInvoice[0].Balance;

      var l_decPaidViaThirdParty = 0;
      var l_decOverAllPaidViaBankCashEtc = 0;
      var l_decPaidViaBankCashEtc = 0;
      var l_decOtherDiscountWriteOff = 0;
      var l_decOverAllOtherDiscountWriteOff = 0;

      for (var i = 0; i < l_arrResponseLineItems.length; i++) {

        if (l_arrResponseLineItems[i].Status == 1) {
          this.LineItemsAmounts.PendingLineItemCount += 1;
        }

        if (l_arrResponseLineItems[i].SourceType == 2 || l_arrResponseLineItems[i].SourceType == 3) {

          if (l_arrResponseLineItems[i].TransactionType == 1) {
            this.LineItemsAmounts.OverAllCredit += l_arrResponseLineItems[i].Amount;
          }
          else {
            this.LineItemsAmounts.OverAllDebit += l_arrResponseLineItems[i].Amount;
          }

          if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
            l_arrLineItems.push(l_arrResponseLineItems[i]);

            if (l_arrResponseLineItems[i].TransactionType == 1) {
              this.LineItemsAmounts.Credit += l_arrResponseLineItems[i].Amount;
            }
            else {
              this.LineItemsAmounts.Debit += l_arrResponseLineItems[i].Amount;
            }
          }
          else {
            for (var as = 0; as < this.AdditionalServices.length; as++) {
              if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
                l_arrLineItems.push(l_arrResponseLineItems[i]);

                if (l_arrResponseLineItems[i].TransactionType == 1) {
                  this.LineItemsAmounts.Credit += l_arrResponseLineItems[i].Amount;
                }
                else {
                  this.LineItemsAmounts.Debit += l_arrResponseLineItems[i].Amount;
                }
              }
            }
          }
        }

        if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 2) //ObjectType=2 means PaidViaThirdParty
          l_decPaidViaThirdParty += l_arrResponseLineItems[i].Amount;

        if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 3) { //ObjectType=3 means manual paid
          l_decOverAllPaidViaBankCashEtc += l_arrResponseLineItems[i].Amount;

          if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
            l_decPaidViaBankCashEtc += l_arrResponseLineItems[i].Amount;
          }
          else {
            for (var as = 0; as < this.AdditionalServices.length; as++) {
              if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
                l_decPaidViaBankCashEtc += l_arrResponseLineItems[i].Amount;
              }
            }
          }
        }

        if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 4) { //ObjectType=4 means Other debit.. adjustment, other discount etc..
          l_decOverAllOtherDiscountWriteOff += l_arrResponseLineItems[i].Amount;

          if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
            l_decOtherDiscountWriteOff += l_arrResponseLineItems[i].Amount;
          }
          else {
            for (var as = 0; as < this.AdditionalServices.length; as++) {
              if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
                l_decOtherDiscountWriteOff += l_arrResponseLineItems[i].Amount;
              }
            }
          }
        }

        if (l_arrResponseLineItems[i].TransactionType == 2 && l_arrResponseLineItems[i].SourceType != 4 && l_arrResponseLineItems[i].ObjectType == 5) { //ObjectType=5 means cancellation
          this.LineItemsAmounts.OverallCancellation += l_arrResponseLineItems[i].Amount;

          if (l_arrResponseLineItems[i].OtherObjectID == this.QuoteID) {
            this.LineItemsAmounts.Cancellation += l_arrResponseLineItems[i].Amount;
          }
          else {
            for (var as = 0; as < this.AdditionalServices.length; as++) {
              if (l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].MNVQuoteID || l_arrResponseLineItems[i].OtherObjectID == this.AdditionalServices[as].ParentID) {
                this.LineItemsAmounts.Cancellation += l_arrResponseLineItems[i].Amount;
              }
            }
          }
        }

        if (l_arrResponseLineItems[i].TransactionType == 3) {
          this.LineItemsAmounts.Refund += l_arrResponseLineItems[i].Amount;
          this.LineItemsAmounts.OverAllRefund += l_arrResponseLineItems[i].Amount;
        }
      }

      this.LineItems = l_arrLineItems;
      this.LineItemsAmounts.Total = this.LineItemsAmounts.Credit;
      this.LineItemsAmounts.OverAllTotal = this.LineItemsAmounts.OverAllCredit;
      this.LineItemsAmounts.Paid = l_decPaidViaBankCashEtc + l_decPaidViaThirdParty;
      this.LineItemsAmounts.OverAllPaid = l_decOverAllPaidViaBankCashEtc + l_decPaidViaThirdParty;
      this.LineItemsAmounts.OtherDiscount = l_decOtherDiscountWriteOff;
      this.LineItemsAmounts.OverAllOtherDiscount = l_decOverAllOtherDiscountWriteOff;

    }, error => console.error(error));
  }

}
