<div (window:resize)="onResize($event)"></div>
<div class="mn-convo-3">
      <span>
        My Conversations
      </span>
      <a class="btn btn-sm btn-primary" (click)="open(content)" *ngIf="!SendWAMessage"><i class="fa fa-plus"></i></a>
    </div>

    <div class="mn-convo-3a mn-scroll-main" [style.height.px]="AvailableHeight - 220" *ngIf="MyConversation.length>0">
      <ng-container *ngFor="let item of MyConversation; let index=index">
        <div [ngClass]="item.UnRead>0 ? 'mn-convo-4 unreadmessages':'mn-convo-4'">
          <a (click)="SendResponseToPage(1,item.ConversationID);Default.IsConversation=false;" [ngClass]="selectedconversation== item.ConversationID? 'mn-convo-5 selectedconvo':'mn-convo-5'">
            <div class="mn-convo-9">
              <span class="mn-convo-6">{{item.Subject}}</span>
              <span style="display: flex;align-items: center;">
                <span *ngIf="Default.IsAdminLoggedIn && selectedconversation == item.ConversationID" (click)="DeleteConversation(item.ConversationID)" style="cursor: pointer;font-size: 12px;margin: 0 10px 0 0;"><i class="far fa-trash-alt"></i></span>
                <span *ngIf="item.LastMessage!=''" class="mn-convo-7">{{item.LastMessageTimeDisplay}}</span>
              </span>
            </div>
            <span *ngIf="item.LastMessage!=''" class="mn-convo-8">{{item.LastMessage}}</span>
            <!--<span class="message-count" style="bottom: 5px;right: 10px;position: absolute;top: auto;">{{item.UnRead}}</span>-->
          </a>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="MyConversation.length<=0">
      <h3 class="mn-convo-10">No conversations</h3>
    </ng-container>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">
      New Conversation
    </h4>
    <button type="button" class="btn btn-sm btn-primary" id="close"
            aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span class="fa fa-times"></span>
    </button>
  </div>
  <div class="modal-body">
    <label>Name</label>
    <input type="text" class="form-control" #conversationName />
    <span class="invalid-feedback" id="name"></span>
    <label class="col-xs-12 col-sm-12 p-0 mb-4 mt-4"><strong>Users</strong></label>
    <div class="col-xs-12 col-sm-12 p-0" style="max-height:500px;overflow:auto;">
      <ng-container *ngFor="let user of checkboxesDataList">
        <div class="col-xs-12 col-sm-6 p-0 float-left mb-2">
          <input type="checkbox" name="" class="form-check" [(ngModel)]="user.IsChecked" (change)="changeSelection()" style="display:inline-block;" />
          <span style="display: inline-block;position: relative;top: -5px;left: 5px;">{{user.Name}}</span>
        </div>
      </ng-container>
    </div>
    <input type="submit" (click)="AddNewConversation(conversationName.value)" class="btn btn-primary float-right mt-4" />
  </div>
</ng-template>
