import { Component, Inject, Input, Output, EventEmitter, Injectable, ChangeDetectionStrategy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { DefaultTS } from '../SharedComponents/Default';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

@Injectable()
@Component({
  selector: 'ConversationList',
  templateUrl: './ConversationList.html',
})
export class ConversationList {
  public http: HttpClient;
  public LoadedConversationID = 0;
  public MyConversation = [] as any;
  public ifSelected = false;
  /*  public ifunread = true;*/
  public checkboxesDataList: any[] = [];
  public selectedItemsList = [];
  public selectedconversation = 0;
  public closeResult = '';
  public UsersData: any[];
  public AvailableHeight = window.innerHeight;
  @Input() public ConversationID = 0;
  @Input() public SendWAMessage = false;
  @Input() childMessage: string;
  @Output() ResponseFromConversationList: EventEmitter<any> = new EventEmitter();
  constructor(http: HttpClient, private router: Router, private router2: ActivatedRoute, private cd: ChangeDetectorRef, private zone: NgZone, public Default: DefaultTS, private modalService: NgbModal, @Inject(PLATFORM_ID) private platformId: Object) {
    this.http = http;
    this.LoadSiteUser()
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.CheckAndRefreshDataTimeStamp();
      }, 5000);
    }

    //setInterval(() => {
    //  this.LoadConversationList();
    //}, 5000);
  }
  ngOnInit() {

  }
  ngAfterViewInit() {
    this.AvailableHeight = window.innerHeight;
  }
  onResize(event) {
    this.AvailableHeight = window.innerHeight;
  }
  ngOnChanges() {
    debugger;
    this.LoadConversationList();
    if (this.childMessage === "Hello") {
      this.LoadConversationList();
    }
  }

  public LoadConversationList() {
    /*  this.Default.SetMasterAnimation(true);*/
   
    let data = {} as any;
    data.ConversationID = this.ConversationID; 

    this.http.post('api/Conversation/LoadConversationList', data).subscribe(result => {
      /*  this.Default.SetMasterAnimation(false);*/
      let l_MyConversation = result[0] as any;

      if (this.SendWAMessage == true) {
        this.MyConversation = this.Default.FilterJsonExactlyMatching(l_MyConversation, 'ObjectType', 100);
      } else {
        let TempMyConversation = [];
        for (var i = 0; i < l_MyConversation.length; i++) {
          if (l_MyConversation[i].ObjectType !== 100) {
            TempMyConversation.push(l_MyConversation[i]);
          }
        }
        this.MyConversation = TempMyConversation;
      }

      if (this.LoadedConversationID == 0 && this.MyConversation.length > 0) {
        this.SendResponseToPage(1, this.MyConversation[0].ConversationID);
      }
      if (this.MyConversation.length == 0) {
        this.SendResponseToPage(1, 1);
      }

      this.UpdateRecordsTimeStamp();
    }, error => console.error(error));
  }

  public AddNewConversation(name) {
    if (name === '') {
      //document.getElementById('name').innerHTML = "Conversation name is required";
      return false;
    }

    let data = {} as any;
    data.Name = name;
    data.UserList = this.selectedItemsList;
    this.Default.SetMasterAnimation(true);
    this.http.post('api/Conversation/NewConversation', data).subscribe(result => {
      if (Number(result) > 0) {
        this.ConversationID = Number(result);
        this.LoadConversationList();
        this.UpdateRecordsTimeStamp();
      }

      this.Default.SetMasterAnimation(false);
      if (isPlatformBrowser(this.platformId)) {
        document.getElementById('close').click();
      }
    }, error => console.error(error));
    return true;
  }
  public changeSelection() {
    this.fetchSelectedItems();
  }
  public SendResponseToPage(Status, ConversationID) {

    let data = {} as any;
    data.Status = Status;
    data.ConversationID = ConversationID;
    this.ResponseFromConversationList.emit(data);
    this.ifSelected = true;
    this.selectedconversation = ConversationID;
    //this.MarkReadMessage(Status, ConversationID)
  }

  public MarkReadMessage(status, conversationId) {
    let data = {} as any;
    data.ConversationID = conversationId;
    data.IsRead = status;

    this.http.post('api/Conversation/MarkRead', data).subscribe(result => {
    }, error => console.error(error));
  }

  public DeleteConversation(ConversationId) {
    let data = {} as any;
    data.ConversationID = ConversationId;
    this.http.post('api/Conversation/DeleteConversation', data).subscribe(result => {
      this.LoadConversationList();
    }, error => console.error(error));
  }

  public CheckAndRefreshDataTimeStamp() {
    this.UpdateRecordsTimeStamp();
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.CheckAndRefreshDataTimeStamp();
      }, 5000);
    }
  }
  public LoadSiteUser() {
    let data = {} as any;
    data.FirstName = "";
    data.LastName = "";
    data.Email = "";
    this.http.post('api/BaseCompany/LoadUsersDataChats', data).subscribe(result => {
      this.Default.SetMasterAnimation(false);

      this.UsersData = result[0] as any;
      //this.StaticPagesData = result[0] as any;
      //this.LinkedRolesData = result[1] as any;
      //this.RolesData = result[2] as any;

      for (var i = 0; i < this.UsersData.length; i++) {
        let data = {} as any;
        data.Id = this.UsersData[i].SiteUserID;
        data.Name = this.UsersData[i].FirstName + " " + this.UsersData[i].LastName;
        data.IsChecked = false;

        this.checkboxesDataList.push(data);
      }
    }, error => console.error(error));
  }
  public UpdateRecordsTimeStamp() {
    for (var i = 0; i < this.MyConversation.length; i++) {
      var l_oDate1 = new Date(this.MyConversation[i]['LastMessageTime']);
      var l_oDate2 = this.Default.ConvertDateToETFormat(new Date());

      var l_decSeconds = this.Default.GetDifferenceBetweenTwoDatesInSeconds(l_oDate1, l_oDate2);
      var l_decMinutes = Math.round(l_decSeconds / 60);
      var l_decHours = Math.round(l_decMinutes / 60);
      var l_decDays = Math.round(l_decHours / 24);

      if (l_decSeconds < 60)
        this.MyConversation[i]['LastMessageTimeDisplay'] = '< 1m';
      else if (l_decMinutes < 60)
        this.MyConversation[i]['LastMessageTimeDisplay'] = l_decMinutes + 'm';
      else if (l_decHours < 24)
        this.MyConversation[i]['LastMessageTimeDisplay'] = l_decHours + 'h';
      else if (l_decDays < 7)
        this.MyConversation[i]['LastMessageTimeDisplay'] = l_decDays + 'd';
      else
        this.MyConversation[i]['LastMessageTimeDisplay'] = l_oDate1.getMonth().toString().padStart(2, '0') + '/' + l_oDate1.getDate().toString().padStart(2, '0') + '/' + l_oDate1.getFullYear();
    }
  }
  open(content) {
    /*  this.FKEntities = [];*/
    this.checkUncheckAll();

    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
      return value.IsChecked
    });
  }
  checkUncheckAll() {
    for (var i = 0; i < this.checkboxesDataList.length; i++) {
      this.checkboxesDataList[i].IsChecked = false;
    }
  }
}
