import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DefaultTS } from './Default';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardMover implements CanActivate { 
  constructor(private router: Router, public Default: DefaultTS) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const response: boolean = await this.Default.CheckIsMoverLoggedInUserAndHasPageAccess(route.url[0].path);
    // toPromise() converts Observable to Promise (async/await only works on Promises)
    // the code 'waits' at the above line till the server request is resolved
    var isUserLoggedIn = response[0].Response;
    if (!isUserLoggedIn) {
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }

}
