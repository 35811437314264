import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { DefaultTS } from '../SharedComponents/Default';

@Injectable()

@Component({
  selector: 'FileUploader',
  templateUrl: './FileUploader.html'
})
export class FileUploaderCustom {
  //  @ViewChild("fileInput") fileInput;
  public http: HttpClient;
  public filebase64: string;
  public baseUrl: string;
  constructor(http: HttpClient, private zone: NgZone, public Default: DefaultTS,) {
    this.http = http;
    this.baseUrl = this.Default.URLBase;
    this.filebase64 = "";
  }
  public AddFile() {
    //  this.AddFileInQueueForUploading(this.fileInput);
  }
  public async AddFileInQueueForUploading(InputElement: any) {
    let SelectedFiles = InputElement.nativeElement;
    if (SelectedFiles.files && SelectedFiles.files[0]) {
      let fileToUpload = SelectedFiles.files[0];
      await this.SendRequestForUploadingToServer(fileToUpload, fileToUpload.name, 1).then((response) => {
      });
    }
  }

  public async AddFileInQueueForUploadingV2(InputElement: any, Name: any) {
    let SelectedFiles = InputElement.nativeElement;
    if (SelectedFiles.files && SelectedFiles.files[0]) {
      let fileToUpload = SelectedFiles.files[0];

      await this.SendRequestForUploadingToServer(fileToUpload, Name, 1).then((response) => {
        if (response[0].Status != 1) {
          this.Default.ShowMessage(2, response[0].Message);
        }
        this.Default.FileUploaderStatus = response[0].Status;
      });
    }
  }

  public async AddFileInQueueForUploadingV3(InputElement: any, Name: any, UploadType: any) {// UploadType=1 means images and files, UploadType=2 means Images only, UploadType=3 means files only
    let SelectedFiles = InputElement.nativeElement;
    if (SelectedFiles.files && SelectedFiles.files[0]) {
      let fileToUpload = SelectedFiles.files[0];

      await this.SendRequestForUploadingToServer(fileToUpload, Name, UploadType).then((response) => {
        if (response[0].Status != 1) {
          this.Default.ShowMessage(2, response[0].Message);
        }
        this.Default.FileUploaderStatus = response[0].Status; 
      });
    }
  }

  public async AddFileInQueueForUploadingV3DragDrop(fileToUpload: any, Name: any, UploadType: any) { // UploadType=1 means images and files and videos, UploadType=2 means Images only, UploadType=3 means files only, UploadType=4 means Videos only
    if (this.Default.IsFileSizeLesserThanAlowedSizeMB(fileToUpload.size, "MB", 500)) {
      await this.SendRequestForUploadingToServer(fileToUpload, Name, UploadType).then((response) => {
        console.log(response);
        this.filebase64 = response[0].FileURL;
        if (response[0].Status != 1) {
          this.Default.ShowMessage(2, response[0].Message);
        }
        this.Default.FileUploaderStatus = response[0].Status;
      });
    }
    else {
      this.Default.ShowMessage(2, 'You are allowed to upload files upto only 50 MBs.');
      this.Default.FileUploaderStatus = 0;
    }
  }

  public handlePromiseError(error: Response): Promise<any> {
    throw (error);
  }

  public SendRequestForUploadingToServer(fileToUpload: any, FileName: any, UploadType: any): Promise<any> {
    let input = new FormData();
    input.append("file", fileToUpload, FileName);
    input.append('UploadType', UploadType);

    return this.http.post(this.baseUrl + "api/FileUploader/UploadFile", input).pipe(map((response: any) => response as any))
      .toPromise()
      .catch(this.handlePromiseError);
  }
}
