<app-nav-menu></app-nav-menu>
<div style="display:inline-block; width:100%;min-height:90vh;">
  <router-outlet></router-outlet>
</div>
<!-- Footer Section -->
<footer class="cs-footer cs-style1 cs-color2" style="margin-top: 45px;" *ngIf="!Default.IsPorter">
  <div class="cs-height__110 cs-height__lg__70" style="display:none;"></div>
  <div class="container" style="display:none;">
    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <div class="cs-footer__item">
          <h2 class="cs-widget__title">About</h2>
          <div class="cs-footer__widget__text">After establishing our service in 2009, we've come a long way from our initial fleet of just two vans. We decided that our priority would be customer satisfaction and service quality.</div>
          <div class="cs-height__15 cs-height__lg__15"></div>
          <div class="cs-social__btns cs-style1 cs-color1">
            <a href="https://www.facebook.com/pages/Londons-Finest-Man-and-Van-Movers/151430138229261" title="Facebook"><i class="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com/FinestManVan" title="Twitter"><i class="fab fa-twitter"></i></a>
            <!--<a href="https://www.linkedin.com/"><i class="fab fa-linkedin"></i></a>
            <a href="https://www.google.com/+FinestmanvanCoUk101"><i class="fab fa-google-plus"></i></a>-->
          </div>
        </div>
      </div><!-- .col -->
      <div class="col-lg-3 col-sm-6">
        <div class="cs-footer__item widget_nav_menu">
          <h2 class="cs-widget__title">Services</h2>
          <ul class="menu">
            <li><a href="/services/removals">Removals</a></li>
            <li><a href="/services/order-packaging-and-boxes">Boxes &amp; Packaging</a></li>
            <li><a href="/services/office-relocation">Office Relocation</a></li>
          </ul>
        </div>
      </div><!-- .col -->
      <div class="col-lg-3 col-sm-6">
        <div class="cs-footer__item widget_nav_menu">
          <h2 class="cs-widget__title">Links</h2>
          <ul class="menu">
            <li><a href="/#SectionAbout">About</a></li>
            <!--<li><a href="/services/removals">Services</a></li>-->
            <li><a href="/privacy">Privacy Policy</a></li>
            <li><a href="/terms-and-conditions">Terms & conditions</a></li>
          </ul>
        </div>
      </div><!-- .col -->
      <div class="col-lg-3 col-sm-6">
        <div class="cs-footer__item cs-address__widgert">
          <h2 class="cs-widget__title">Contact Info</h2>
          <ul>
            <li><i class="fas fa-map-marker-alt"></i>Reedworth St, London SE11 4PE, United Kingdom</li>
            <li><i class="fas fa-envelope"></i>enquiries@finestvan.co.uk</li>
            <li><i class="fas fa-headset"></i>0207 870 1091</li>
            <li style="padding: 0 0 0 31px;">
              Company No: 13548177
            </li>
            <li style="padding: 0 0 0 31px;">VAT No: 422 4804 21</li>
          </ul>
        </div>
      </div><!-- .col -->
    </div>
    <!-- <div class="cs-height__85 cs-height__lg__40"></div> -->
  </div>
  <div class="cs-copyright text-center" style="padding:10px 0;">
    <div class="container">{{'© '+currentYear+' - Finest Van. All Rights Reserved.'}} </div>
  </div>
  <div class="cs-parallax__shape cs-position1">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13px" height="13px">
      <path fill-rule="evenodd" fill="rgb(97, 192, 49)" d="M6.500,-0.000 C10.090,-0.000 13.000,2.910 13.000,6.500 C13.000,10.090 10.090,13.000 6.500,13.000 C2.910,13.000 -0.000,10.090 -0.000,6.500 C-0.000,2.910 2.910,-0.000 6.500,-0.000 Z" />
    </svg>
  </div>
  <div class="cs-parallax__shape cs-position4">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px">
      <path fill-rule="evenodd" fill="rgb(62, 80, 110)" d="M5.000,-0.000 C7.761,-0.000 10.000,2.238 10.000,5.000 C10.000,7.761 7.761,10.000 5.000,10.000 C2.238,10.000 -0.000,7.761 -0.000,5.000 C-0.000,2.238 2.238,-0.000 5.000,-0.000 Z" />
    </svg>
  </div>
  <div class="cs-parallax__shape cs-position5">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15px" height="15px">
      <path fill-rule="evenodd" fill="rgb(112, 87, 248)" d="M7.500,-0.000 C11.642,-0.000 15.000,3.358 15.000,7.500 C15.000,11.642 11.642,15.000 7.500,15.000 C3.358,15.000 -0.000,11.642 -0.000,7.500 C-0.000,3.358 3.358,-0.000 7.500,-0.000 Z" />
    </svg>
  </div>
</footer>
<!-- Footer Section -->

<div id="cs-scrollup"><i class="fas fa-chevron-up"></i></div>
