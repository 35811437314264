import { Component, Inject, Input, Output, PLATFORM_ID, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DefaultTS } from '../SharedComponents/Default';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { QuoteService } from '../Quote/quote.service';
import { json } from 'express';

@Component({
  selector: 'app-MNVLineItems',
  templateUrl: './MNVLineItems.html'
})
export class MNVLineItems {
  public ShowErrors = [] as any;
  @Input() public QuoteID;
  @Input() public MNVJobID;
  @Input() public LineItemType;
  @Output() ClosePopupAndLoadLineItem = new EventEmitter();

  public data = {} as any;
  constructor(public datepipe: DatePipe, public http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, private defaultTS: DefaultTS, private router: Router, public service: QuoteService, private route: Router) {
    this.ClearLineItem();
  }

  ngOnChanges() {
    this.ClearLineItem();
  }


  public ClearLineItem() {
    this.data.Type = "1";
    if (this.LineItemType == 0)
      this.data.Type = "2";
    this.data.Amount = 0;
    this.data.MarkPaid = false;
    this.data.HideOnInvoice = false;
    this.data.Note = "";
    this.data.RefID = "";
    this.ShowErrors[0] = '';
    this.ShowErrors[1] = '';
  }

  public SaveLineItem() {
    this.ShowErrors[0] = '';
    this.ShowErrors[1] = '';
    if (this.data.Amount > 0 && this.data.RefID != "") {
      this.AjaxSaveLineItem();
    }
    else {
      if (this.data.Amount <=0 ) {
        this.ShowErrors[1] = 'Enter amount greater than 0.';
      }
      if (this.data.RefID == "") {
        this.ShowErrors[0] = 'Required.';
      }
    }
  }

  public AjaxSaveLineItem() {
    let data = {} as any;
    data.Type = this.data.Type;
    data.Amount = this.data.Amount;
    data.Note = this.data.Note;
    data.RefID = this.data.RefID;
    data.QuoteID = this.QuoteID;
    data.MNVJobID = this.MNVJobID;
    data.LineItemType = this.LineItemType;
    data.MarkPaid = this.data.MarkPaid;
    data.HideOnInvoice = this.data.HideOnInvoice;

    if (data.Note == null || data.Note == "")
      data.Note = data.RefID;

    (<HTMLInputElement>document.getElementById('loader')).style.display = 'flex';

    this.http.post('api/MNVQuote/AjaxSaveLineItem', data).subscribe(result => {

      (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';

      var StatusData = result[0][0];
      if (StatusData.Status == 1) {
        this.ClosePopupAndLoadLineItem.emit("OK");
        this.defaultTS.ShowMessage(1, 'Line item has been added successfully.');
      }
      else {
        this.defaultTS.ShowMessage(2, StatusData.Message);
      }
    });
  }
}
