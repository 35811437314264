<div class="container small-container">
  
  <div class="row">
    <div class="col-sm-12 col-md-6 col-xl-6" style="margin-top:40px !important;" [ngClass]="Error.IsFromFill == true? '':'RedBorderClass'">
     
      <ng-autocomplete placeholder="From Postcode"
                       [data]="from"
                       [(ngModel)]="fromSelected"
                       [searchKeyword]="keyword"
                       [itemTemplate]="itemTemplate"
                       (inputChanged)="onFromChangeSearch($event)"
                       (selected)="onFromSelected($event)">
      </ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.address"></a>
      </ng-template>
      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
    <div class="col-sm-12 col-md-6 col-xl-6" style="margin-top:40px !important;" [ngClass]="Error.IsToFill == true? '':'RedBorderClass'">
      <ng-autocomplete placeholder="To Postcode" [data]="to"
                       [(ngModel)]="toSelected"
                       [searchKeyword]="keyword"
                       [itemTemplate]="itemTemplate"
                       (inputChanged)="onToChangeSearch($event)"
                       (selected)="onToSelected($event)">
      </ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.address"></a>
      </ng-template>
      <ng-template #notFoundTemplate let-notFound>

        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
    <div class="row" style="padding-right:0;">
      <div class="col-lg-4 offset-lg-4" style="padding-right:0;">
        <div class="cs-height__50 cs-height__lg__50"></div>
        <button class="cs-btn cs-style1 cs-no__border cs-color7 cs-primary__font w-100 cs-rounded__btn" (click)="GoToQuote()">Get a quote</button>
      </div>
    </div>
  </div>
</div>
<!--<div class="cs-preloader2" id="my_loader" style="display:none">
  <div class="cs-preloader__in">
    <img src="assets/img/movers/logo.png" alt="">
  </div>
</div>-->
