<a id="ShowModal" data-toggle="modal" data-target="#myModal"></a>
<!--MM:: Modal For Simple Message-->
<div class="defaultmodal modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style="opacity:1;z-index:1050 !important;">
    <div class="modal-dialog" role="document" style="max-width:300px !important;margin-top:170px;">
        <div class="modal-content">
          <div class="modal-body">
            <div style="display:flex;align-items:center;justify-content:flex-end;">
              <a data-dismiss="modal" style="font-weight:bold;"><i class="fa fa-times"></i></a>
            </div>
            <span id="Message" class="defaultmsg">{{Message}}</span>
          </div>
        </div>
    </div>
</div>
