<ng-container *ngIf="UserReview.length>0">

  <!--For star rating-->
  <div class="cs-slider cs-style1 cs-testimonial__slider__style1 cs-type1" style="box-shadow:0px 0px 20px 0px rgba(94, 44, 237, 0.15);">
    <div class="card-body">
      <h3 class="cs-testimonial__avatar_name" *ngIf="IsReferenceScreen">Customer Testimonials</h3>
      <h3 class="cs-testimonial__avatar_name" *ngIf="!IsReferenceScreen">Customer Testimonials for Removals in {{PageName}}</h3>
      <h4 *ngIf="UserReview.length>0 && UserReview[0].ParentID==0 " class="cs-testimonial__avatar_designation" style="font-size:16px;">Man and Van & Removals in {{PageName}} - Finest Man Van</h4>
      <h4 *ngIf="UserReview.length>0 && UserReview[0].ParentID>0 " class="cs-testimonial__avatar_designation" style="font-size:16px;">{{'Man and Van & Removals in '+ PageName+' | Postcode: '+UserReview[0].PostCode}} </h4>
      <p>
        {{TotalRating}}
        <!--<ng-container *ngFor="let review of TotalRating; let index=index">
          <rating [(ngModel)]="review.Rating" [max]="5" [readonly]="true"></rating>
        </ng-container>-->
        <rating [(ngModel)]="TotalRating" [max]="5" [readonly]="true"></rating> stars  - based on {{UserReview.length}} rating(s)
      </p>
    </div>
  </div>
  <br />
</ng-container>

<ng-container *ngFor="let review of UserReview; let index=index">
  <div class="cs-slider cs-style1 cs-testimonial__slider__style1 cs-type1" style="box-shadow:0px 0px 20px 0px rgba(94, 44, 237, 0.15);">
    <div class="card-body">
      <p style="white-space: pre-wrap;">{{review.Comments}}</p>
      <rating [(ngModel)]="review.Rating" [max]="5" [readonly]="true"></rating>
      <div class="col-md-12">{{review.Title}} - {{review.CreationTimestamp | date:'mediumDate'}}</div>
    </div>
  </div>
  <br />
</ng-container>



<!--<span itemtype="https://schema.org/Product" itemscope>
  <meta itemprop="name" content="Finest Man & Van in {{PageName}}." />
  <meta itemprop="description" content="London's Finest Man & Van service in {{PageName}}." />
  <span itemprop="brand" itemtype="https://schema.org/Thing" itemscope>
    <meta itemprop="name" content="Finest Man & Van" />
  </span>
  <span itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
    <meta itemprop="reviewCount" content="{{UserReviewAggergate.TotalCount}}" />
    <meta itemprop="ratingValue" content="{{UserReviewAggergate.TotalRating}}" />
  </span>
</span>-->


<!--<ng-container *ngFor="let review of UserReview; let index=index">
  <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
    <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
      <meta itemprop="name" content="{{review.Title}}" />
    </div>
    <div itemprop="reviewRating" itemtype="https://schema.org/Rating" itemscope>
      <meta itemprop="ratingValue" content="{{review.Rating}}" />
      <meta itemprop="bestRating" content="5" />
    </div>
  </div>
</ng-container>-->
