<header class="cs-site__header cs-style1" [ngClass]="!Default.IsPorter ?'cs-site__header cs-style1 cs-style-out':'cs-site__header cs-style1 cs-style-in'" *ngIf="ShowHeader">
  <div class="cs-main__header" style="border-bottom: 1px solid #ccc;">
    <div class="container">
      <div class="cs-main__header__in" *ngIf="!Default.HideHeader">
        <div class="cs-main__header__left">
          <a class="cs-site__branding" href="/" *ngIf="!Default.IsPorter"><img src="assets/img/movers/logo-updated.png" alt="Logo"></a>
        </div>
        <div class="cs-main__header__right" (click)="Default.ScrollToTop();">
          <div class="cs-nav">
            <ng-container *ngIf="!ShowTopMenu">
              <ul [ngClass]="showmenumobile ? 'cs-nav__list show-menu-mobile':'cs-nav__list'">
                <ng-container *ngIf="Default.IsUserLoggedIn">
                  <li class="menu-item-has-children">
                    <a>
                      <span>{{Default.UserInitials}}</span>
                    </a>
                    <ul>
                      <li (click)="Default.PageURL = 'home'" [ngClass]="Default.PageURL == 'home' || Default.PageURL == ''? 'active':''"><a href="{{Default.BaseWebURL}}">Go To WebSite</a></li>
                      <li> <a [routerLink]="['/setting']">Settings</a></li>
                      <li> <a (click)="LogoutUser();">Logout</a></li>
                    </ul>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
            <span class="cs-munu__toggle" (click)="showmenumobile = !showmenumobile;" *ngIf="!Default.IsPorter"><span></span></span>
            <span class="cs-munu__toggle" (click)="showmenumobileleft = !showmenumobileleft;" *ngIf="Default.IsPorter"><span></span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>


<ng-container *ngIf="Default.IsPorter">
  <div class="mnv-left-menu" (click)="AjaxLoginCheckLoginData();" [ngClass]="showmenumobileleft ?'lmhide':'lmshow'">
    <ul>
      <ng-container *ngIf="Default.Role.includes('Admin')">
        <li (click)="Default.PageURL = 'Dashboard'" [ngClass]="Default.PageURL == 'Dashboard'? 'active':''">
          <a [routerLink]="['/dashboard']" (click)="CloseLeftMenuAndNavigate();">Dashboard</a>
        </li>
        <li (click)="Default.PageURL = 'calendar'" [ngClass]="Default.PageURL == 'calendar'? 'active':''">
          <a [routerLink]="['/calendar']" (click)="CloseLeftMenuAndNavigate();">Scheduling</a>
        </li>
        <li (click)="Default.PageURL = 'allquotes'" [ngClass]="Default.PageURL == 'allquotes'? 'active':''">
          <a [routerLink]="['/allquotes']" (click)="CloseLeftMenuAndNavigate();">Quotes</a>
        </li>
        <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'earningsdashboard'" [ngClass]="Default.PageURL == 'earningsdashboard'? 'active':''">
          <a [routerLink]="['/earningsdashboard']" (click)="CloseLeftMenuAndNavigate();">Earnings</a>
        </li>
        <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'tippingdashboard'" [ngClass]="Default.PageURL == 'tippingdashboard'? 'active':''">
          <a [routerLink]="['/tippingdashboard']" (click)="CloseLeftMenuAndNavigate();">Tipping</a>
        </li>
        <li (click)="Default.PageURL = 'invoicedata'" [ngClass]="Default.PageURL == 'invoicedata'? 'active':''">
          <a [routerLink]="['/invoicedata']" (click)="CloseLeftMenuAndNavigate();">Invoices</a>
        </li>
        <li (click)="Default.PageURL = 'receiptsdata'" [ngClass]="Default.PageURL == 'receiptsdata'? 'active':''">
          <a [routerLink]="['/receiptsdata']" (click)="CloseLeftMenuAndNavigate();">Receipts</a>
        </li>
        <li (click)="Default.PageURL = 'clients'" [ngClass]="Default.PageURL == 'clients'? 'active':''">
          <a [routerLink]="['/clients']" (click)="CloseLeftMenuAndNavigate();">Clients</a>
        </li>
        <li (click)="Default.PageURL = 'advertisementdashboard'" [ngClass]="Default.PageURL == 'advertisementdashboard'? 'active':''">
          <a [routerLink]="['/marketingscore']" (click)="CloseLeftMenuAndNavigate();">Marketing Score</a>
        </li>
        <li (click)="Default.PageURL = 'support'" [ngClass]="Default.PageURL == 'support'? 'active':''">
          <a [routerLink]="['/support']" (click)="CloseLeftMenuAndNavigate();">Support</a>
        </li>
        <li (click)="Default.PageURL = 'myconversations'" [ngClass]="Default.PageURL == 'myconversations'? 'active':''">
          <a [routerLink]="['/myconversations']" (click)="CloseLeftMenuAndNavigate();">Messages</a>
        </li>
        <li (click)="Default.PageURL = 'waconversations'" [ngClass]="Default.PageURL == 'waconversations'? 'active':''">
          <a [routerLink]="['/waconversations']" (click)="CloseLeftMenuAndNavigate();">WhatsApp Messages</a>
        </li>
        <li (click)="Default.PageURL = 'blogarticle'" [ngClass]="Default.PageURL == 'blogarticle'? 'active':''">
          <a [routerLink]="['/blogarticle']" (click)="CloseLeftMenuAndNavigate();">Blog Article</a>
        </li>
        <li class="menu-item-has-children">
          <a (click)="ShowMenutab1 = !ShowMenutab1">
            <span>Website</span>
            <span [ngClass]="ShowMenutab1 ?'fa fa-chevron-down chev-out':'fa fa-chevron-right chev-out'"></span>
          </a>
          <ul *ngIf="ShowMenutab1">
            <li (click)="Default.PageURL = 'location'" [ngClass]="Default.PageURL == 'location'? 'active':''">
              <a [routerLink]="['/location']" (click)="CloseLeftMenuAndNavigate();">Locations</a>
            </li>
            <li (click)="Default.PageURL = 'pages'" [ngClass]="Default.PageURL == 'pages'? 'active':''">
              <a [routerLink]="['/pages']" (click)="CloseLeftMenuAndNavigate();">Pages</a>
            </li>
            <li (click)="Default.PageURL = 'userReview'" [ngClass]="Default.PageURL == 'userReview'? 'active':''">
              <a [routerLink]="['/userReview']" (click)="CloseLeftMenuAndNavigate();">User Review</a>
            </li>
          </ul>
          <a (click)="ShowMenutab = !ShowMenutab">
            <span>Configuration</span>
            <span [ngClass]="ShowMenutab ?'fa fa-chevron-down chev-out':'fa fa-chevron-right chev-out'"></span>
          </a>
          <ul *ngIf="ShowMenutab">
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'user'" [ngClass]="Default.PageURL == 'user'? 'active':''">
              <a [routerLink]="['/user']" (click)="CloseLeftMenuAndNavigate();">User</a>
            </li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'rates'" [ngClass]="Default.PageURL == 'rates'? 'active':''">
              <a [routerLink]="['/rates']" (click)="CloseLeftMenuAndNavigate();">Rates</a>
            </li>
            <li (click)="Default.PageURL = 'mover'" [ngClass]="Default.PageURL == 'mover'? 'active':''"><a [routerLink]="['/mover']" (click)="CloseLeftMenuAndNavigate();">Mover</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'moverrates'" [ngClass]="Default.PageURL == 'moverrates'? 'active':''"><a [routerLink]="['/moverrates']" (click)="CloseLeftMenuAndNavigate();">Mover Rates</a></li>
            <li (click)="Default.PageURL = 'moverunavailability'" [ngClass]="Default.PageURL == 'moverunavailability'? 'active':''"><a [routerLink]="['/moverunavailability']" (click)="CloseLeftMenuAndNavigate();">Mover Unavailability</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'movertypes'" [ngClass]="Default.PageURL == 'movertypes'? 'active':''"><a [routerLink]="['/movertypes']" (click)="CloseLeftMenuAndNavigate();">Mover Types</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'vantypes'" [ngClass]="Default.PageURL == 'vantypes'? 'active':''"><a [routerLink]="['/vantypes']" (click)="CloseLeftMenuAndNavigate();">Van Types</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'van'" [ngClass]="Default.PageURL == 'van'? 'active':''"><a [routerLink]="['/van']" (click)="CloseLeftMenuAndNavigate();">Vans</a></li>
            <li (click)="Default.PageURL = 'discount'" [ngClass]="Default.PageURL == 'discount'? 'active':''"><a [routerLink]="['/discount']" (click)="CloseLeftMenuAndNavigate();">Discount</a></li>
            <li (click)="Default.PageURL = 'campaign'" [ngClass]="Default.PageURL == 'campaign'? 'active':''"><a [routerLink]="['/campaign']" (click)="CloseLeftMenuAndNavigate();">Campaign</a></li>
            <li (click)="Default.PageURL = 'campaigndata'" [ngClass]="Default.PageURL == 'campaigndata'? 'active':''"><a [routerLink]="['/campaigndata']" (click)="CloseLeftMenuAndNavigate();">Campaign Data</a></li>
            <li (click)="Default.PageURL = 'holiday'" [ngClass]="Default.PageURL == 'holiday'? 'active':''"><a [routerLink]="['/holiday']" (click)="CloseLeftMenuAndNavigate();">Holiday</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'timeslot'" [ngClass]="Default.PageURL == 'timeslot'? 'active':''"><a [routerLink]="['/timeslot']" (click)="CloseLeftMenuAndNavigate();">Time Slot</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'slots'" [ngClass]="Default.PageURL == 'slots'? 'active':''"><a [routerLink]="['/slots']" (click)="CloseLeftMenuAndNavigate();">Slots Management</a></li>
            <li (click)="Default.PageURL = 'van_availability'" [ngClass]="Default.PageURL == 'van_availability'? 'active':''"><a [routerLink]="['/van_availability']" (click)="CloseLeftMenuAndNavigate();">Van Availability</a></li>
            <li (click)="Default.PageURL = 'largevans'" [ngClass]="Default.PageURL == 'largevans'? 'active':''"><a [routerLink]="['/largevans']" (click)="CloseLeftMenuAndNavigate();">Use Luton as Transit</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'systemconfiguration'" [ngClass]="Default.PageURL == 'systemconfiguration'? 'active':''"><a [routerLink]="['/systemconfiguration']" (click)="CloseLeftMenuAndNavigate();">System Configuration</a></li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'emailtemplate'" [ngClass]="Default.PageURL == 'emailtemplate'? 'active':''">
              <a [routerLink]="['/emailtemplate']" (click)="CloseLeftMenuAndNavigate();">Email Templates</a>
            </li>
            <li *ngIf="!Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'generaldata'" [ngClass]="Default.PageURL == 'generaldata'? 'active':''">
              <a [routerLink]="['/generaldata']" (click)="CloseLeftMenuAndNavigate();">General Data</a>
            </li>
            <li (click)="Default.PageURL = 'minimumcharge'" [ngClass]="Default.PageURL == 'minimumcharge'? 'active':''">
              <a [routerLink]="['/minimumcharge']" (click)="CloseLeftMenuAndNavigate();">Minimum Charges</a>
            </li>
            <li (click)="Default.PageURL = 'bankholiday'" [ngClass]="Default.PageURL == 'bankholiday'? 'active':''">
              <a [routerLink]="['/bankholiday']" (click)="CloseLeftMenuAndNavigate();">Bank Holidays</a>
            </li>
            <li (click)="Default.PageURL = 'recentjob'" [ngClass]="Default.PageURL == 'recentjob'? 'active':''">
              <a [routerLink]="['/recentjob']" (click)="CloseLeftMenuAndNavigate();">Recent Jobs</a>
            </li>
            <li (click)="Default.PageURL = 'locationstats'" [ngClass]="Default.PageURL == 'locationstats'? 'active':''">
              <a [routerLink]="['/locationstats']" (click)="CloseLeftMenuAndNavigate();">Location Stats</a>
            </li>
            <li (click)="Default.PageURL = 'locationmove'" [ngClass]="Default.PageURL == 'locationmove'? 'active':''">
              <a [routerLink]="['/locationmove']" (click)="CloseLeftMenuAndNavigate();">Location Move</a>
            </li>
          </ul>
        </li>
      </ng-container>

      <!--Mover Menus-->
      <ng-container *ngIf="Default.Role.includes('Driver') || Default.Role.includes('Helper')">
        <li (click)="Default.PageURL = 'driverdashboard'" [ngClass]="Default.PageURL == 'driverdashboard'? 'active':''">
          <a [routerLink]="['/driverdashboard']" (click)="CloseLeftMenuAndNavigate();">Dashboard</a>
        </li>

        <li *ngIf="(Default.IsDriverAsContractorLoggedIn || Default.IsAdminLoggedIn) && !Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'earningsdashboard'" [ngClass]="Default.PageURL == 'earningsdashboard'? 'active':''">
          <a [routerLink]="['/earningsdashboard']" (click)="CloseLeftMenuAndNavigate();">Earnings</a>
        </li>


        <li *ngIf="(Default.IsDriverAsContractorLoggedIn || Default.IsAdminLoggedIn) && !Default.IsSemiAdminLoggedIn" (click)="Default.PageURL = 'tippingdashboard'" [ngClass]="Default.PageURL == 'tippingdashboard'? 'active':''">
          <a [routerLink]="['/tippingdashboard']" (click)="CloseLeftMenuAndNavigate();">Tipping</a>
        </li>

        <li (click)="Default.PageURL = 'myconversations'" [ngClass]="Default.PageURL == 'myconversations'? 'active':''">
          <a [routerLink]="['/myconversations']" (click)="CloseLeftMenuAndNavigate();">Messages</a>
        </li>
        <li (click)="Default.PageURL = 'moverunavailability'" [ngClass]="Default.PageURL == 'moverunavailability'? 'active':''">
          <a [routerLink]="['/moverunavailability']" (click)="CloseLeftMenuAndNavigate();">Mover Unavailability</a>
        </li>
      </ng-container>
      <li class="mn-show-md"><a href="{{Default.BaseWebURL}}">Go To WebSite</a></li>
      <li class="mn-show-md"> <a href="#" (click)="LogoutUser();">Logout</a></li>
    </ul>
  </div>
</ng-container>

<div style="width:0;height:0;overflow:hidden;display:none;">
  <span>{{Default.PageStatusChecks[0]}}</span>
</div>
<Default></Default>
